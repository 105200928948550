import { BulkEditorProduct } from '../../../../../view/products/BulkEditor';
import useImportFunctions from './useImportFunctions';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { InformationContentType } from '../../../../../api/petcloudapi/api';
import useValidationFunctions from './useValidationFunctions';
import usePreviewFunctions from './usePreviewFunctions';
import useProductUnits from '../../../../../hooks/product/useProductUnits';

export enum ColumnHeaderMappingContentType {
  String,
  TranslatedString,
  Number,
  RichText,
  TranslatedRichText,
  Boolean,
  CommaSeperatedURLs,
  Special,
}

export type ColumnHeaderMappingType = {
  key: string;
  mappedKey?: string;
  options?: any;
  contentType: ColumnHeaderMappingContentType;
  importFunc: (
    product: BulkEditorProduct,
    sourceTableRow: any[],
    mappedKeyIndex?: number,
    opts?: any,
    sourceTableHeaders?: string[]
  ) => BulkEditorProduct | ColumnHeaderMappingError;
  previewFunc?: (
    mapping: ColumnHeaderMappingType,
    exampleValues: string[]
  ) => ReactNode;
  validationFunc?: (
    mapping: ColumnHeaderMappingType
  ) => ColumnHeaderMappingError[] | undefined;
  errors?: ColumnHeaderMappingError[];
  locked?: boolean;
};

export type ColumnHeaderMappingError = {
  identifier: string;
  messages: string[];
};

const useDefaultMappings = (
  productIdKey?: string,
  productIdKeyMappedKey?: string
) => {
  const { isTranslationCorrectlyMapped } = useValidationFunctions();
  const {
    importAdditionalInformationBulletPoints,
    importAdditionalInformationRichText,
    importProductUnit,
    importAnalyticConstituents,
    importConvertedNumericValue,
    importInt,
    importFloat,
    importProductPrice,
    importString,
    importToggleValue,
    importTranslatedContent,
  } = useImportFunctions();
  const {
    previewAdditionalInformationDownloads,
    previewAnalyticConstituents,
    previewBulletpoints,
  } = usePreviewFunctions();

  const { productUnits, productUnitsReady } = useProductUnits();
  const [mappingsReady, setMappingsReady] = useState(false);

  useEffect(() => {
    if (productUnitsReady) {
      setMappingsReady(true);
    }
  }, [productUnitsReady]);

  const defaultMappings: ColumnHeaderMappingType[] = useMemo(() => {
    const mappings: ColumnHeaderMappingType[] = [
      {
        key: 'ean',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.String,
        importFunc: (p, r, m) => importString(p, r, m, 'ean'),
      },
      {
        key: 'name',
        mappedKey: undefined,
        options: {
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        contentType: ColumnHeaderMappingContentType.TranslatedString,
        importFunc: (p, r, m, opts, headers) =>
          importTranslatedContent(p, r, m, opts, 'name', headers),
        validationFunc: isTranslationCorrectlyMapped,
      },
      {
        key: 'seoProductTitle',
        mappedKey: undefined,
        options: {
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        contentType: ColumnHeaderMappingContentType.TranslatedString,
        importFunc: (p, r, m, opts, headers) =>
          importTranslatedContent(p, r, m, opts, 'seoProductTitle', headers),
        validationFunc: isTranslationCorrectlyMapped,
      },
      {
        key: 'description',
        mappedKey: undefined,
        options: {
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        contentType: ColumnHeaderMappingContentType.TranslatedRichText,
        importFunc: (p, r, m, opts, headers) =>
          importTranslatedContent(p, r, m, opts, 'description', headers),
        validationFunc: isTranslationCorrectlyMapped,
      },
      {
        key: 'manufacturerProductNumber',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.String,
        importFunc: (p, r, m) =>
          importString(p, r, m, 'manufacturerProductNumber'),
      },
      {
        key: 'price',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.Number,
        importFunc: (p, r, m) => importProductPrice(p, r, m, 'gross'),
      },
      {
        key: 'compareAtPrice',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.Number,
        importFunc: (p, r, m) => importProductPrice(p, r, m, 'listPriceGross'),
      },
      {
        key: 'productLine',
        mappedKey: undefined,
        options: {
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        contentType: ColumnHeaderMappingContentType.TranslatedString,
        importFunc: (p, r, m, opts, headers) =>
          importTranslatedContent(p, r, m, opts, 'productLine', headers),
        validationFunc: isTranslationCorrectlyMapped,
      },
      // {
      //   key: 'marketingText',
      //   mappedKey: undefined,
      //   options: {
      //     languageUniform: true,
      //     languageMap: { 'de-DE': undefined },
      //   },
      //   contentType: ColumnHeaderMappingContentType.TranslatedString,
      //   importFunc: (p, r, m, opts, headers) =>
      //     importTranslatedContent(p, r, m, opts, 'marketingText', headers),
      //   validationFunc: isTranslationCorrectlyMapped,
      // },
      {
        key: 'analyticConstituents',
        mappedKey: undefined,
        options: {
          nameValuesSeparator: ' ',
          valueSeparator: '',
          separator: ',',
        },
        contentType: ColumnHeaderMappingContentType.Special,
        importFunc: importAnalyticConstituents,
        previewFunc: previewAnalyticConstituents,
      },
      {
        key: 'restockTimeDays',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.Number,
        importFunc: (p, r, m) => importInt(p, r, m, 'restockTimeDays'),
      },
      {
        key: 'isCloseout',
        mappedKey: undefined,
        options: {
          trueKey: 'Yes',
          falseKey: 'No',
        },
        contentType: ColumnHeaderMappingContentType.Boolean,
        importFunc: (p, r, m, opts) =>
          importToggleValue(p, r, m, opts, 'isCloseout'),
      },
      {
        key: 'purchaseSteps',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.Number,
        importFunc: (p, r, m) => importInt(p, r, m, 'purchaseSteps'),
      },
      {
        key: 'minPurchase',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.Number,
        importFunc: (p, r, m) => importInt(p, r, m, 'minPurchase'),
      },
      {
        key: 'maxPurchase',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.Number,
        importFunc: (p, r, m) => importInt(p, r, m, 'maxPurchase'),
      },
      {
        key: 'productUnitId',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.Special,
        importFunc: (p, r, m) =>
          importProductUnit(p, r, m, 'productUnitId', productUnits),
      },
      {
        key: 'purchaseUnit',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.Number,
        importFunc: (p, r, m) => importFloat(p, r, m, 'purchaseUnit'),
      },
      {
        key: 'referenceUnit',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.Number,
        importFunc: (p, r, m) => importFloat(p, r, m, 'referenceUnit'),
      },
      {
        key: 'weight',
        mappedKey: undefined,
        options: {
          originalUnit: 'kg',
        },
        contentType: ColumnHeaderMappingContentType.Number,
        importFunc: (p, r, m, opts) =>
          importConvertedNumericValue(p, r, m, opts, 'weight'),
      },
      {
        key: 'width',
        mappedKey: undefined,
        options: {
          originalUnit: 'cm',
        },
        contentType: ColumnHeaderMappingContentType.Number,
        importFunc: (p, r, m, opts) =>
          importConvertedNumericValue(p, r, m, opts, 'width'),
      },
      {
        key: 'height',
        mappedKey: undefined,
        options: {
          originalUnit: 'cm',
        },
        contentType: ColumnHeaderMappingContentType.Number,
        importFunc: (p, r, m, opts) =>
          importConvertedNumericValue(p, r, m, opts, 'height'),
      },
      {
        key: 'length',
        mappedKey: undefined,
        options: {
          originalUnit: 'cm',
        },
        contentType: ColumnHeaderMappingContentType.Number,
        importFunc: (p, r, m, opts) =>
          importConvertedNumericValue(p, r, m, opts, 'length'),
      },
      {
        key: 'packUnit',
        options: {
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        contentType: ColumnHeaderMappingContentType.TranslatedString,
        importFunc: (p, r, m, opts, headers) =>
          importTranslatedContent(p, r, m, opts, 'packUnit', headers),
        validationFunc: isTranslationCorrectlyMapped,
      },
      {
        key: 'packUnitPlural',
        options: {
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        contentType: ColumnHeaderMappingContentType.TranslatedString,
        importFunc: (p, r, m, opts, headers) =>
          importTranslatedContent(p, r, m, opts, 'packUnitPlural', headers),
        validationFunc: isTranslationCorrectlyMapped,
      },
      // {
      //   key: 'isShippingFree',
      //   mappedKey: undefined,
      //   options: {
      //     trueKey: 'Yes',
      //     falseKey: 'No',
      //   },
      //   contentType: ColumnHeaderMappingContentType.Boolean,
      //   importFunc: (p, r, m, opts) =>
      //     importToggleValue(p, r, m, opts, 'isShippingFree'),
      // },
      {
        key: 'isBatchControlled',
        mappedKey: undefined,
        options: {
          trueKey: 'Yes',
          falseKey: 'No',
        },
        contentType: ColumnHeaderMappingContentType.Boolean,
        importFunc: (p, r, m, opts) =>
          importToggleValue(p, r, m, opts, 'isBatchControlled'),
      },
      {
        key: 'isBestBeforeControlled',
        mappedKey: undefined,
        options: {
          trueKey: 'Yes',
          falseKey: 'No',
        },
        contentType: ColumnHeaderMappingContentType.Boolean,
        importFunc: (p, r, m, opts) =>
          importToggleValue(p, r, m, opts, 'isBestBeforeControlled'),
      },
      {
        key: 'isDangerousGoods',
        mappedKey: undefined,
        options: {
          trueKey: 'Yes',
          falseKey: 'No',
        },
        contentType: ColumnHeaderMappingContentType.Boolean,
        importFunc: (p, r, m, opts) =>
          importToggleValue(p, r, m, opts, 'isDangerousGoods'),
      },
      {
        key: 'additionalInformation.Downloads',
        mappedKey: undefined,
        options: {
          separator: ',',
        },
        contentType: ColumnHeaderMappingContentType.CommaSeperatedURLs,
        importFunc: (p, v) => {
          return p;
        },
        previewFunc: previewAdditionalInformationDownloads,
      },
      {
        key: 'additionalInformation.ProductMedia',
        mappedKey: undefined,
        options: {
          separator: ',',
        },
        contentType: ColumnHeaderMappingContentType.CommaSeperatedURLs,
        importFunc: (p, v) => {
          return p;
        },
      },
      {
        key: 'additionalInformation.Highlights',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.Special,
        options: {
          separator: ',',
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        importFunc: (p, r, m, opts, headers) =>
          importAdditionalInformationBulletPoints(
            p,
            r,
            m,
            opts,
            '34d0fd1d-079a-4772-8966-0ddf31acbd59',
            InformationContentType.Highlights,
            headers
          ),
        previewFunc: previewBulletpoints,
        validationFunc: isTranslationCorrectlyMapped,
      },
      {
        key: 'additionalInformation.Hints',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.RichText,
        options: {
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        importFunc: (p, r, m, opts, headers) =>
          importAdditionalInformationRichText(
            p,
            r,
            m,
            opts,
            '624588d8-fc89-4172-81ca-37c6ab839b7e',
            InformationContentType.Hints,
            headers
          ),
        validationFunc: isTranslationCorrectlyMapped,
      },
      {
        key: 'additionalInformation.Storage',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.RichText,
        options: {
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        importFunc: (p, r, m, opts, headers) =>
          importAdditionalInformationRichText(
            p,
            r,
            m,
            opts,
            '067b7c75-8d4b-44ad-8269-fa841808a63d',
            InformationContentType.Storage,
            headers
          ),
        validationFunc: isTranslationCorrectlyMapped,
      },
      {
        key: 'additionalInformation.Material',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.RichText,
        options: {
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        importFunc: (p, r, m, opts, headers) =>
          importAdditionalInformationRichText(
            p,
            r,
            m,
            opts,
            '2f5cf762-5078-4bbd-8bd6-b66bc5177830',
            InformationContentType.Material,
            headers
          ),
        validationFunc: isTranslationCorrectlyMapped,
      },
      {
        key: 'additionalInformation.Dimensions',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.RichText,
        options: {
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        importFunc: (p, r, m, opts, headers) =>
          importAdditionalInformationRichText(
            p,
            r,
            m,
            opts,
            'd4d1392c-f732-47b8-bcd4-c17d1fbc26bb',
            InformationContentType.Dimensions,
            headers
          ),
        validationFunc: isTranslationCorrectlyMapped,
      },
      {
        key: 'additionalInformation.MeasurementInstructions',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.RichText,
        options: {
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        importFunc: (p, r, m, opts, headers) =>
          importAdditionalInformationRichText(
            p,
            r,
            m,
            opts,
            '4db8adb2-67c0-4e58-aaf7-98c5e78c5337',
            InformationContentType.MeasurementInstructions,
            headers
          ),
        validationFunc: isTranslationCorrectlyMapped,
      },
      {
        key: 'additionalInformation.TipsAndApplication',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.RichText,
        options: {
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        importFunc: (p, r, m, opts, headers) =>
          importAdditionalInformationRichText(
            p,
            r,
            m,
            opts,
            '7c205b73-3da6-45de-a5fb-60bb85f390d2',
            InformationContentType.TipsAndApplication,
            headers
          ),
        validationFunc: isTranslationCorrectlyMapped,
      },
      {
        key: 'additionalInformation.ProductCharacteristics',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.RichText,
        options: {
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        importFunc: (p, r, m, opts, headers) =>
          importAdditionalInformationRichText(
            p,
            r,
            m,
            opts,
            '06619c0e-e61b-429d-90fa-6eb4a7a5abba',
            InformationContentType.ProductCharacteristics,
            headers
          ),
        validationFunc: isTranslationCorrectlyMapped,
      },
      {
        key: 'additionalInformation.Composition',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.RichText,
        options: {
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        importFunc: (p, r, m, opts, headers) =>
          importAdditionalInformationRichText(
            p,
            r,
            m,
            opts,
            '93823bfc-a523-486d-aa28-203ca230b443',
            InformationContentType.Composition,
            headers
          ),
        validationFunc: isTranslationCorrectlyMapped,
      },
      {
        key: 'additionalInformation.Additives',
        mappedKey: undefined,
        contentType: ColumnHeaderMappingContentType.RichText,
        options: {
          languageUniform: true,
          languageMap: { 'de-DE': undefined },
        },
        importFunc: (p, r, m, opts, headers) =>
          importAdditionalInformationRichText(
            p,
            r,
            m,
            opts,
            'b40452c3-2f5e-4e27-857f-3eae74d78456',
            InformationContentType.Additives,
            headers
          ),
        validationFunc: isTranslationCorrectlyMapped,
      },
    ];

    // if this is part of a product update process, lock the mapping with the
    // selected productIdKey and automatically pre-map the key
    return mappings.map((mapping) => {
      if (productIdKeyMappedKey && mapping.key === productIdKey) {
        return {
          ...mapping,
          mappedKey: productIdKeyMappedKey,
          locked: true,
        };
      } else {
        return mapping;
      }
    });
  }, [productUnits, productIdKey, productIdKeyMappedKey]);

  return {
    defaultMappings,
    mappingsReady,
  };
};

export default useDefaultMappings;
