import './priceseditor.css';
import { ProductPriceResponse } from '../../../../api/petcloudapi/api';
import Price from '../../../../sections/product/pricing/Price';
import { DropdownOption } from '../../../../elements/selectors/Selectors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EditorActions,
  EditorUpdateCallback,
} from '../../../../features/jsontable/JsonTable';
import Popup from '../../../../elements/popup/Popup';
import NewPriceWizard from '../../../../sections/product/pricing/NewPriceWizard';
import UniversalProductPrice from '../../../../types/UniversalProductPrice';
import { addEmptyPrice } from '../../../productbulkeditor/utils/addEmptyPrice';

interface PricesEditorProps {
  value: ProductPriceResponse[];
  currencyOptions: DropdownOption[];
  countryOptions: DropdownOption[];
  updateCallback: EditorUpdateCallback;
  productId: string;
}

const PricesEditor: React.FC<PricesEditorProps> = ({
  value,
  currencyOptions,
  countryOptions,
  updateCallback,
  productId,
}) => {
  const { t } = useTranslation();
  const [prices, setPrices] = useState(value ?? []);
  const [newPriceWizard, setNewPriceWizard] = useState(false);

  const addPrice = (price: UniversalProductPrice) => {
    const update = [...prices];
    update.push(addEmptyPrice(price, productId));
    setPrices(update);
    setNewPriceWizard(false);
  };

  return (
    <div className={'jtce-prices-editor'}>
      <div className={'jtce-title'}>
        {t('view.productBulkEditor.headers.productPrices')}
      </div>
      <div className={'jtce-prices-editor-prices'}>
        {prices.map((price, i) => {
          return (
            <Price
              index={i}
              price={price}
              updatePrice={(index, price) => {
                const update = [...prices];
                update[index] = { ...(price as ProductPriceResponse) };
                setPrices(update);
              }}
              deletePrice={(id, index) => {
                const update = [...prices];
                update.splice(index, 1);
                setPrices(update);
              }}
              currencyOptions={currencyOptions}
              countryOptions={countryOptions}
              mode={'NEW'}
            />
          );
        })}
      </div>
      {updateCallback ? (
        <EditorActions
          save={() => {
            try {
              updateCallback([{ value: prices }]);
            } catch {}
          }}
          buttons={[
            {
              cta: t('view.product.pricing.new'),
              action: () => setNewPriceWizard(true),
            },
          ]}
        />
      ) : null}
      <Popup
        width={'30%'}
        toggled={newPriceWizard}
        close={() => setNewPriceWizard(false)}
      >
        <div className={'popup-title'}>{t('view.product.pricing.new')}</div>
        <NewPriceWizard
          currencyOptions={currencyOptions}
          countryOptions={countryOptions}
          currentPrices={prices}
          inlineCreationCallback={addPrice}
        />
      </Popup>
    </div>
  );
};

export default PricesEditor;
