import { Has } from '../../contexts/auth/Authorization';
import { Navigator, Stage } from '../../layout/stage/Stage';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Card, { CardSection } from '../../elements/card/Card';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import {
  AnimalSpeciesResponse,
  DeliveryTimeResponse,
  ProductAdditionalInformationResponse,
  ProductAnalyticConstituentResponse,
  ProductAssetResponse,
  ProductCategoryResponse,
  ProductListFilterMode,
  ProductPriceResponse,
  ProductResponse,
  ProductState,
  ProductStockResponse,
  ProductVariantResponse,
  PropertyGroupResponse,
  PropertyOptionResponse,
  TagResponse,
  TaxTypeIdentifier,
  TranslatedTypeResponseOfString,
} from '../../api/petcloudapi/api';
import ProductList from '../../features/productlist/ProductList';
import Button from '../../elements/button/Button';
import ProductBulkEditor from '../../features/productbulkeditor/ProductBulkEditor';
import StepSelector from '../../features/productbulkeditor/stepselector/StepSelector';
import useFileDownload from '../../hooks/useFileDownload';
import useUrlParams from '../../hooks/useUrlParams';
import ProgressBar from '../../elements/progressbar/ProgressBar';
import ProductImporter from '../../features/productbulkeditor/productimporter/ProductImporter';
import useDateTools from '../../hooks/useDateTools';
import { addEmptyPrice } from '../../features/productbulkeditor/utils/addEmptyPrice';
import useClipboardFunctions from '../../features/productbulkeditor/utils/useClipboardFunctions';
import { EditorUpdateCallbackInstruction } from '../../features/jsontable/JsonTable';
import usePromiseBatcher from '../../features/productbulkeditor/utils/usePromiseBatcher';

export enum BulkEditorState {
  Mode,
  ProductSelection,
  Import,
  Editor,
}

export enum BulkEditorProductMode {
  Create,
  Update,
}

export type BulkUpdateInstruction = {
  rowIndex: number;
  columnKey: string;
  value: any;
};

export type SessionInfo = {
  id: string;
  name?: string;
  lastSavedAt: string;
};

interface BulkEditorProps {
  setLockNavigation: (lock: boolean) => void;
}

const BulkEditor: React.FC<BulkEditorProps> = ({ setLockNavigation }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productBulkEditor',
  });
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();
  const { downloadFile } = useFileDownload();
  const { getUrlParamBool } = useUrlParams();
  const { displayReadableDate } = useDateTools();
  const { bulkEditorClipboardFunctionsConfig } = useClipboardFunctions();
  const [step, setStep] = useState<BulkEditorState>(BulkEditorState.Mode);
  const [productListLoaded, setProductListLoaded] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
  const [products, setProducts] = useState<BulkEditorProduct[]>([]);

  const {
    loadProgress,
    resetLoadProgress,
    incrementLoadProgress,
    batchPromises,
  } = usePromiseBatcher<ProductResponse>();
  const [groupedProducts, setGroupedProducts] = useState<{
    [parentId: string]: any[];
  } | null>(null);
  const [sessionInfo, setSessionInfo] = useState<SessionInfo | null>(null);

  useEffect(() => {
    if (step === BulkEditorState.Editor) {
      setLockNavigation(true);
    } else {
      setLockNavigation(false);
    }
  }, [step]);

  useEffect(() => {
    if (getUrlParamBool('loadIds')) {
      const idString = localStorage.getItem('productBulkEditor_loadIds');
      if (idString) {
        const ids = JSON.parse(idString);
        setStep(BulkEditorState.Editor);
        const copy = getUrlParamBool('copy');
        const directIds = getUrlParamBool('directIds');
        fetchAllProducts(ids, copy, directIds);
        localStorage.removeItem('productBulkEditor_loadIds');
        console.log('LOADED IDS');
      }
    }
  }, []);

  /**
   * Fetches products based on the given ids and transforms them into BulkEditorProducts.
   * @param {string[]} ids - The ids of the products to fetch.
   * @param {boolean} [copy] - Determines whether to create a copy of the products or not.
   * @param {boolean} directIds - If set to true the process will not automatically fetch variants of parent products
   */
  const fetchAllProducts = (
    ids: string[],
    copy?: boolean,
    directIds?: boolean
  ) => {
    // fetch the products of the given ids
    const promises = ids.map((id) => {
      return () => getProduct(id);
    });

    resetLoadProgress(promises.length, t('loadProgress.loadingParents'));

    batchPromises(10, promises).then((pr) => {
      // if fetched products had variants, also fetch them
      const variantPromises: (() => Promise<ProductResponse>)[] = [];
      pr.forEach((r) => {
        r.children?.forEach((c) => {
          // check if id was already part of the selected products to be loaded
          if (!ids.includes(c.id) && !directIds) {
            variantPromises.push(() => getProduct(c.id));
          }
        });
      });

      resetLoadProgress(promises.length, t('loadProgress.loadingChildren'));

      batchPromises(10, variantPromises).then((vr) => {
        const results: BulkEditorProduct[] = [];
        pr.forEach((r) => {
          const newId = crypto.randomUUID();
          results.push(
            transFormToBulkEditorProduct(r, copy ? newId : undefined)
          );
          r.children?.forEach((c) => {
            const newVariantId = crypto.randomUUID();

            // if variant has already been loaded at the top, don't push it another time
            const preVr = pr.find((x) => x.id === c.id);
            const variant = vr.find((v) => v.id === c.id);
            if (!preVr && variant) {
              results.push(
                transFormToBulkEditorProduct(
                  variant,
                  copy ? newVariantId : undefined,
                  copy ? newId : undefined
                )
              );
            }
          });
        });

        groupAndSetProducts(results);
      });
    });
  };

  const getGroupedProducts = (products: BulkEditorProduct[]) => {
    const groupedProducts: { [parentId: string]: any[] } = {};
    products
      .filter((p) => !p.parentId)
      .forEach((p) => (groupedProducts[p.id] = [p]));
    products.forEach((product) => {
      if (product.parentId) {
        if (groupedProducts[product.parentId]) {
          groupedProducts[product.parentId].push(product);
        } else {
          groupedProducts[product.id] = [product];
        }
      }
    });
    return groupedProducts;
  };

  const groupAndSetProducts = (products: BulkEditorProduct[]) => {
    const groupedProducts = getGroupedProducts(products);

    const reIndexedByGroup: BulkEditorProduct[] = [];
    Object.entries(groupedProducts).forEach(([id, items]) => {
      items.forEach((item) => {
        reIndexedByGroup.push(item);
      });
    });

    setProducts(reIndexedByGroup);
    setGroupedProducts(groupedProducts);
  };

  const finalizeProductImport = (products: BulkEditorProduct[]) => {
    groupAndSetProducts(products);
    setStep(BulkEditorState.Editor);
  };

  const transFormToBulkEditorProduct = (
    response: ProductResponse,
    newId?: string,
    newParentId?: string
  ): BulkEditorProduct => {
    const { id, parentId, ...rest } = response;
    return {
      id: newId ?? id,
      parentId: newParentId ?? parentId,
      mode: newId ? BulkEditorProductMode.Create : BulkEditorProductMode.Update,
      ...rest,
    };
  };

  const getProduct = (id: string): Promise<ProductResponse> => {
    return new Promise((resolve, reject) => {
      productsApi
        .productsGetProductById(id, true)
        .then((response) => {
          console.log(response);
          resolve(response.data);
          incrementLoadProgress();
        })
        .catch((error) => {
          console.log(error);
          errorHandler.addError(error.response);
          reject();
        });
    });
  };

  const addEmptyProduct = (item?: BulkEditorProduct) => {
    const emptyProduct = { ...getEmptyBulkEditorProduct() };
    const updateP = [...products];

    const index = updateP.findIndex((p) => p.id === item?.id);
    if (index > -1) {
      updateP.splice(index + 1, 0, emptyProduct);
    } else {
      updateP.push(emptyProduct);
    }
    setProducts(updateP);

    // const updateG = { ...groupedProducts };
    // updateG[emptyProduct.id] = [emptyProduct];
    // setGroupedProducts(updateG);
    groupAndSetProducts(updateP);
  };

  const deleteProducts = (ids: string[]) => {
    const updatedProducts = [...products];
    let updatedGroupedProducts = { ...groupedProducts };

    ids.forEach((id) => {
      // Find the index of the product that will be deleted
      const index = updatedProducts.findIndex((p) => p.id === id);

      // If the product is not found, or it's the only left, do nothing.
      if (index === -1 || updatedProducts.length === 1) return;

      // Update the groupedProducts object
      const deletedProduct = updatedProducts[index];

      // Update the product array
      updatedProducts.splice(index, 1);

      // If the product was a parent product, remove the parent <> child group
      // additionally remove the parentId of all child products
      if (updatedGroupedProducts[id]) {
        const childIds: string[] = updatedGroupedProducts[id].map((x) => x.id);

        console.log('deleted grouped product with id: ' + id);
        delete updatedGroupedProducts[id];

        childIds.forEach((id) => {
          const i = updatedProducts.findIndex((p) => p.id === id);
          if (i > -1) {
            updatedProducts[i].parentId = null;
          }
        });
      }

      // If the product was a child product, remove it from its parent's array in the object
      if (
        deletedProduct.parentId &&
        updatedGroupedProducts &&
        updatedGroupedProducts[deletedProduct.parentId]
      ) {
        updatedGroupedProducts[deletedProduct.parentId] =
          updatedGroupedProducts[deletedProduct.parentId].filter(
            (product) => product.id !== id
          );
      }
    });
    groupAndSetProducts(updatedProducts);
  };

  const setAsParentForSelection = (
    product: ProductResponse,
    selectedIds: string[]
  ) => {
    const update = [...products];

    // set the parentId field for all variants
    selectedIds.forEach((id) => {
      const i = update.findIndex((x) => x.id === id);
      if (i !== -1) {
        update[i] = {
          ...update[i],
          parentId: product.id,
          hasVariants: false,
        };
      }
    });

    // set the hasVariants flag for the parent product
    const pId = update.findIndex((x) => x.id === product.id);
    update[pId] = {
      ...update[pId],
      parentId: null,
      hasVariants: true,
      productPrices: [
        addEmptyPrice(
          {
            id: crypto.randomUUID(),
            currencyId: 'f0902077-da87-4a7f-9bbd-c3e05a1f4a22',
            countryId: 'd4e0cf47-25fd-4f2a-9847-3c127c31c52e',
            gross: 0,
          },
          update[pId].id
        ),
      ],
    };
    groupAndSetProducts(update);
  };

  const saveStateToFile = () => {
    const encoded = encodeURIComponent(JSON.stringify(products));
    const blob = new Blob([encoded], {
      type: 'application/json',
    });
    downloadFile(blob, 'inpetto_productTable.json');
  };

  const loadStateFromFile = (files: File[]) => {
    setProducts([]);
    setGroupedProducts(null);
    files[0].arrayBuffer().then((buffer) => {
      const uint8Array = new Uint8Array(buffer);
      const data = uint8Array.reduce(
        (acc, i) => (acc += String.fromCharCode.apply(null, [i])),
        ''
      );
      const result = JSON.parse(decodeURIComponent(data));
      console.log(result);
      groupAndSetProducts(result);
    });
  };

  const updateItem = (
    itemId: string,
    instructions: EditorUpdateCallbackInstruction[]
  ) => {
    const update = [...products];

    const itemIndex = products.findIndex((x) => x.id === itemId);
    if (itemIndex > -1) {
      instructions.forEach((instruction) => {
        if (!instruction.headerKey) {
          throw new Error(
            'Instruction headerKey is required at level of BulkEditor update function.'
          );
        }
        let v = instruction.value;
        if (typeof v === 'string') {
          v = v.trim();
        }
        update[itemIndex] = {
          ...update[itemIndex],
          [instruction.headerKey]: v,
        };
        console.log(`NEW VALUE IS: ${v}`);
      });
    }
    console.log(update);
    setProducts([...update]);
  };

  const bulkUpdateItem = (instructions: BulkUpdateInstruction[]) => {
    const update = products;
    instructions.forEach((instruction) => {
      if (instruction.rowIndex < update.length) {
        update[instruction.rowIndex] = {
          ...update[instruction.rowIndex],
          [instruction.columnKey]: instruction.value,
        };
      }
    });
    setProducts([...update]);
  };

  const getNavigatorTitle = () => {
    switch (step) {
      case BulkEditorState.Mode:
        return t('title.mode');
      case BulkEditorState.ProductSelection:
        return t('title.productSelection');
      case BulkEditorState.Import:
        return t('title.import');
      case BulkEditorState.Editor:
        return sessionInfo?.name ? sessionInfo.name : t('title.newSession');
    }
  };

  const renderStep = () => {
    switch (step) {
      case BulkEditorState.Mode:
        return (
          <Card bigScreenWidth="100%" spanToViewport>
            <CardSection>
              <StepSelector
                setStep={setStep}
                addEmptyProduct={addEmptyProduct}
                setSessionInfo={setSessionInfo}
                setProducts={finalizeProductImport}
              />
            </CardSection>
          </Card>
        );
      case BulkEditorState.ProductSelection:
        return (
          <Card bigScreenWidth="100%">
            <CardSection>
              <ProductList
                selectProductCallback={(product) => {
                  const update = [...selectedProductIds];
                  const i = update.findIndex((id) => id === product.id);
                  if (i !== -1) {
                    update.splice(i, 1);
                  } else {
                    update.push(product.id);
                  }
                  setSelectedProductIds(update);
                }}
                selectAllProductsCallback={(array) => {
                  setSelectedProductIds(array.map((x) => x.id));
                }}
                productListFilterMode={ProductListFilterMode.ParentProductsOnly}
                adjustHeightToViewportOffset={260}
                allowActions={false}
                finishedLoadingCallback={() => setProductListLoaded(true)}
              />
              {productListLoaded ? (
                <div
                  className={
                    'global-cardActions global-cardActions-spaceBetween productBulkEditor-productListActions'
                  }
                >
                  <Button
                    cta={t('actions.back')}
                    look={'secondary'}
                    width={'minimal'}
                    action={() => {
                      setStep(BulkEditorState.Mode);
                    }}
                  />
                  <Button
                    cta={t('actions.next')}
                    look={'secondary'}
                    width={'minimal'}
                    action={() => {
                      setStep(BulkEditorState.Editor);
                      fetchAllProducts(selectedProductIds);
                    }}
                  />
                </div>
              ) : null}
            </CardSection>
          </Card>
        );
      case BulkEditorState.Import:
        return (
          <Card bigScreenWidth={'100%'} spanToViewport>
            <CardSection>
              <ProductImporter setProducts={finalizeProductImport} />
            </CardSection>
          </Card>
        );
      case BulkEditorState.Editor:
        return (
          <Card bigScreenWidth="100%">
            <CardSection>
              {products.length > 0 && groupedProducts ? (
                <ProductBulkEditor
                  products={products}
                  defaultSearchableHeaders={[
                    'ean',
                    'manufacturerProductNumber',
                    'name',
                  ]}
                  groupedProducts={groupedProducts}
                  updateItem={updateItem}
                  bulkUpdateItem={bulkUpdateItem}
                  addEmptyProduct={addEmptyProduct}
                  deleteProduct={(id) => deleteProducts([id])}
                  deleteProducts={deleteProducts}
                  setAsParentForSelection={setAsParentForSelection}
                  sessionInfo={sessionInfo}
                  setSessionInfo={setSessionInfo}
                  saveStateToFile={saveStateToFile}
                  loadStateFromFile={loadStateFromFile}
                  clipboardFunctions={bulkEditorClipboardFunctionsConfig}
                />
              ) : loadProgress ? (
                <div style={{ height: '20vh', padding: '10vw' }}>
                  <ProgressBar
                    title={loadProgress.title}
                    progress={loadProgress.loaded}
                    total={loadProgress.total}
                    withLoader
                  />
                </div>
              ) : (
                'error'
              )}
            </CardSection>
          </Card>
        );
    }
  };

  return (
    <Has
      authorizations={[
        'products:create',
        'products:edit',
        'property_groups:list',
        'product_units:list',
        'product_groups:list',
        'product_categories:list',
        'currency:list',
        'countries:list',
        'warehouses:list',
        'asset_folders:list',
        'brands:list',
      ]}
      showNoAuthorization
    >
      <Stage>
        <Navigator
          title={getNavigatorTitle()}
          badges={
            step === BulkEditorState.Editor
              ? sessionInfo
                ? [
                    {
                      title: `${t('title.savedAt')} ${displayReadableDate(
                        sessionInfo.lastSavedAt
                      )}`,
                      color: 'var(--color-success)',
                    },
                  ]
                : [
                    {
                      title: t('title.notSaved'),
                      color: 'var(--color-text_secondary)',
                    },
                  ]
              : undefined
          }
        ></Navigator>
        {renderStep()}
      </Stage>
    </Has>
  );
};

export default BulkEditor;

export const getEmptyBulkEditorProduct = (): BulkEditorProduct => ({
  mode: BulkEditorProductMode.Create,
  parentId: null,
  ean: null,
  manufacturerProductNumber: null,
  name: {
    'de-DE': 'Neues Produkt',
    'en-GB': 'New Product',
  },
  seoProductTitle: null,
  animalSpecies: [],
  productGroupId: null,
  description: {
    'de-DE': null,
    'en-GB': null,
  },
  brandId: null,
  productLine: {
    'de-DE': null,
    'en-GB': null,
  },
  marketingText: null,
  releaseDate: '2022-01-03T15:32:05.78418+00:00',
  mainVariantId: null,
  variantOptions: [],
  children: [],
  properties: [],
  propertyGroups: [],
  restockTimeDays: null,
  isCloseout: false,
  taxTypeIdentifier: TaxTypeIdentifier.Full,
  purchaseSteps: 1,
  minPurchase: 1,
  maxPurchase: null,
  purchaseUnit: null,
  referenceUnit: null,
  productUnitId: null,
  packUnit: {
    'de-DE': 'Stück',
    'en-GB': 'Piece',
  },
  packUnitPlural: {
    'de-DE': 'Stück',
    'en-GB': 'Pieces',
  },
  weight: null,
  width: null,
  height: null,
  length: null,
  deliveryTimes: [],
  isShippingFree: false,
  coverId: null,
  cover: null,
  tags: [],
  categories: [],
  mainCategoryId: null,
  hasVariants: null,
  state: ProductState.Draft,
  isBatchControlled: false,
  isBestBeforeControlled: false,
  isDangerousGoods: false,
  productPrices: [],
  productStocks: null,
  productAssets: [],
  additionalInformation: [],
  analyticConstituents: [],
  manufacturerId: null,
  id: crypto.randomUUID(),
});

export type BulkEditorProduct = {
  mode: BulkEditorProductMode;
  id: string;
  parentId?: string | null;
  ean: string | null;
  manufacturerProductNumber?: string | null;
  name: TranslatedTypeResponseOfString | null;
  seoProductTitle?: TranslatedTypeResponseOfString | null;
  animalSpecies: AnimalSpeciesResponse[] | null;
  productGroupId: string | null;
  description: TranslatedTypeResponseOfString | null;
  brandId?: string | null;
  productLine?: TranslatedTypeResponseOfString | null;
  marketingText?: TranslatedTypeResponseOfString | null;
  releaseDate: string | null;
  mainVariantId?: string | null;
  variantOptions?: PropertyOptionResponse[] | null;
  properties?: PropertyOptionResponse[] | null;
  propertyGroups?: PropertyGroupResponse[] | null;
  restockTimeDays?: number | null;
  isCloseout?: boolean | null;
  taxTypeIdentifier: TaxTypeIdentifier;
  purchaseSteps?: number | null;
  minPurchase?: number | null;
  maxPurchase?: number | null;
  purchaseUnit?: number | null;
  referenceUnit?: number | null;
  weight?: number | null;
  width?: number | null;
  height?: number | null;
  length?: number | null;
  productUnitId?: string | null;
  packUnit?: TranslatedTypeResponseOfString | null;
  packUnitPlural?: TranslatedTypeResponseOfString | null;
  deliveryTimes?: DeliveryTimeResponse[] | null;
  isShippingFree?: boolean | null;
  coverId?: string | null;
  cover?: ProductAssetResponse | null;
  tags?: TagResponse[] | null;
  categories?: ProductCategoryResponse[] | null;
  mainCategoryId?: string | null;
  hasVariants?: boolean | null;
  state: ProductState;
  isBatchControlled?: boolean | null;
  isBestBeforeControlled?: boolean | null;
  isDangerousGoods?: boolean | null;
  productPrices?: ProductPriceResponse[] | null;
  productStocks?: ProductStockResponse[] | null;
  productAssets?: ProductAssetResponse[] | null;
  additionalInformation?: ProductAdditionalInformationResponse[] | null;
  analyticConstituents?: ProductAnalyticConstituentResponse[] | null;
  manufacturerId: string | null;
  children?: ProductVariantResponse[] | null;
  parentIdKeyValue?: string | null;
  idKeyValue?: string | null;
};

export type ValidatedBulkEditorProduct = {
  id: string;
  parentId?: string | null;
  ean: string | null;
  manufacturerProductNumber?: string | null;
  name: TranslatedTypeResponseOfString;
  seoProductTitle?: TranslatedTypeResponseOfString | null;
  animalSpecies: AnimalSpeciesResponse[];
  productGroupId: string;
  description: TranslatedTypeResponseOfString;
  brandId: string;
  productLine?: TranslatedTypeResponseOfString | null;
  marketingText?: TranslatedTypeResponseOfString | null;
  releaseDate: string;
  mainVariantId?: string | null;
  variantOptions?: PropertyOptionResponse[] | null;
  properties?: PropertyOptionResponse[] | null;
  propertyGroups?: PropertyGroupResponse[] | null;
  restockTimeDays?: number | null;
  isCloseout?: boolean;
  taxTypeIdentifier: TaxTypeIdentifier;
  purchaseSteps?: number | null;
  minPurchase?: number | null;
  maxPurchase?: number | null;
  purchaseUnit?: number | null;
  referenceUnit?: number | null;
  weight?: number | null;
  width?: number | null;
  height?: number | null;
  length?: number | null;
  productUnitId?: string | null;
  packUnit?: TranslatedTypeResponseOfString | null;
  packUnitPlural?: TranslatedTypeResponseOfString | null;
  deliveryTimes?: DeliveryTimeResponse[] | null;
  isShippingFree?: boolean | null;
  coverId?: string | null;
  cover?: ProductAssetResponse | null;
  tags?: TagResponse[] | null;
  categories: ProductCategoryResponse[];
  mainCategoryId?: string | null;
  hasVariants?: boolean | null;
  state: ProductState;
  isBatchControlled?: boolean;
  isBestBeforeControlled?: boolean;
  isDangerousGoods?: boolean;
  productPrices?: ProductPriceResponse[] | null;
  productStocks?: ProductStockResponse[] | null;
  productAssets?: ProductAssetResponse[] | null;
  additionalInformation?: ProductAdditionalInformationResponse[] | null;
  analyticConstituents?: ProductAnalyticConstituentResponse[] | null;
  manufacturerId: string | null;
};
