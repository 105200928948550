const useListTools = <T extends { id: string }>() => {
  const toggleItem = (items: T[], newItem: T, identifier?: keyof T): T[] => {
    const update = [...items];
    const predicate = identifier
      ? (item: any) => item[identifier] === newItem[identifier]
      : (item: any) => item.id === newItem.id;
    const i = update.findIndex(predicate);
    if (i !== -1) {
      update.splice(i, 1);
    } else {
      update.push(newItem);
    }
    return update;
  };

  const toggleAllItems = (
    items: T[] | null | undefined,
    newItems: T[]
  ): T[] => {
    if (items && items.length === newItems.length) {
      return [];
    } else {
      return [...newItems];
    }
  };

  //TODO: replace this in ProductList and implement in StockPerProductList
  const selectTranche = (tranche: T[], items: T[] | null | undefined): T[] => {
    if (items) {
      const trancheAlreadySelected = tranche.every((x) =>
        items.find((y) => y.id === x.id)
      );

      if (trancheAlreadySelected) {
        const removedTranche = [...items];
        tranche.forEach((item) => {
          const i = removedTranche.findIndex((x) => x.id === item.id);
          if (i !== -1) {
            removedTranche.splice(i, 1);
          }
        });
        return removedTranche;
      } else {
        const result = [...items];
        tranche.forEach((item) => {
          if (!result.includes(item)) {
            result.push(item);
          }
        });
        return result;
      }
    } else {
      return [];
    }
  };

  return {
    toggleItem,
    toggleAllItems,
    selectTranche,
  };
};

export default useListTools;
