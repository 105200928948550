import './columnheaderconfig.css';
import { SheetData } from '../ProductImporter';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Button from '../../../../elements/button/Button';
import MappingOptions from './mappingoptions/MappingOptions';
import useDefaultMappings, {
  ColumnHeaderMappingType,
} from './hooks/useDefaultMappings';
import ColumnHeaderConfigProfiles from './columnheaderconfigprofiles/ColumnHeaderConfigProfiles';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import ColumnHeaderMapping, {
  columnHeaderMappingContentTypeIcons,
} from './columnheadermapping/ColumnHeaderMapping';

interface ColumnHeaderConfigProps {
  sheetData: SheetData;
  mapData: (
    mappings: ColumnHeaderMappingType[],
    sheetData: SheetData,
    setMappingsCallback?: (mappings: ColumnHeaderMappingType[]) => void
  ) => void;
}

const ColumnHeaderConfig: React.FC<ColumnHeaderConfigProps> = ({
  sheetData,
  mapData,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productBulkEditor.productImporter.columnHeaderConfig',
  });
  const { defaultMappings, mappingsReady } = useDefaultMappings(
    sheetData.productIdKey,
    sheetData.productIdKeyMappedKey
  );
  const [mappings, setMappings] = useState<ColumnHeaderMappingType[]>([]);
  const [selectedMappingKey, setSelectedMappingKey] = useState<string>('ean');

  useEffect(() => {
    if (mappingsReady) {
      setMappings(defaultMappings);
    }
  }, [mappingsReady]);

  const setMapping = (m: ColumnHeaderMappingType, index: number) => {
    setMappings((prev) => {
      const update = [...prev];
      update[index] = { ...m };
      return update;
    });
  };

  const getExampleValues = (key: string, limitLength?: boolean) => {
    const rowIndex = sheetData.headers.indexOf(key);
    const values: string[] = [];
    sheetData.data.slice(0, 10).forEach((row) => {
      const v: string = row[rowIndex]?.toString();
      if (!!v) {
        if (limitLength) {
          const sliced = v.slice(0, 20);
          values.push(v.length > 20 ? `${sliced}...` : sliced);
        } else {
          values.push(v);
        }
      }
    });
    return values;
  };

  const removeMappingError = (index: number, identifier: string) => {
    setMappings((prevMappings) => {
      const updatedMappings = prevMappings ? [...prevMappings] : [];
      const errors = updatedMappings[index].errors;
      if (errors) {
        errors.splice(
          errors.findIndex((err) => err.identifier === identifier),
          1
        );
      }
      updatedMappings[index].errors = errors;
      return updatedMappings;
    });
  };

  // checks whether the user is OK to proceed
  const isConfigValid = () => {
    // if the sheetData is configured to update existing products (the productIdKey is set),
    // there needs to be at least one complete mapping beside the productIdKey
    const { productIdKey } = sheetData;
    if (productIdKey) {
      return !!mappings.find((x) => x.key !== productIdKey && x.mappedKey);
    } else {
      // otherwise check if there is at least one mapped mapping
      return !!mappings.find((m) => m.mappedKey);
    }
  };

  const onMapping = (value: string, index: number) => {
    setMappings((prevMappings) => {
      const updatedMappings = prevMappings ? [...prevMappings] : [];
      updatedMappings[index].mappedKey = value;
      if (updatedMappings[index].options?.languageMap !== undefined) {
        updatedMappings[index].options.languageMap['de-DE'] = value;
      }
      updatedMappings[index].errors = undefined;
      return updatedMappings;
    });
    removeMappingError(index, 'mappedKey');
  };

  const onDeleteMapping = (index: number) => {
    setMappings((prevMappings) => {
      const updatedMappings = prevMappings ? [...prevMappings] : [];
      updatedMappings[index].mappedKey = undefined;
      updatedMappings[index].errors = undefined;
      return updatedMappings;
    });
  };

  if (mappingsReady && mappings.length > 0) {
    const selectedMappingIndex = mappings.findIndex(
      (m) => m.key === selectedMappingKey
    );

    const selectedMapping = mappings[selectedMappingIndex];
    return (
      <>
        <div className={'columnHeaderConfig'}>
          <div className={'columnHeaderConfig-list'}>
            <div className={'columnHeaderConfig-list-header'}>
              <div className={'columnHeaderConfig-list-header-column'}>
                {t('targetHeader')}
              </div>
              <div className={'columnHeaderConfig-list-header-column'}>
                <div className={'columnHeaderConfig-list-header-column-title'}>
                  {t('sourceHeader')}
                </div>
                <ColumnHeaderConfigProfiles
                  currentMappings={mappings}
                  setCurrentMappings={setMappings}
                  sheetData={sheetData}
                />
              </div>
            </div>
            <div className={'columnHeaderConfig-list-items-wrapper'}>
              <div className={'columnHeaderConfig-list-items'}>
                {mappings.map((mapping, index) => {
                  const active = selectedMappingKey === mapping.key;
                  return (
                    <ColumnHeaderMapping
                      mapping={mapping}
                      index={index}
                      active={active}
                      onSetActiveMappingKey={setSelectedMappingKey}
                      sheetData={sheetData}
                      completedMappings={mappings.filter((x) => x.mappedKey)}
                      onMapping={onMapping}
                      onDeleteMapping={onDeleteMapping}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className={'columnHeaderConfig-info'} key={selectedMapping.key}>
            <div className={'columnHeaderConfig-info-config'}>
              <div className={'columnHeaderConfig-info-config-title'}>
                {t(`headers.${selectedMapping.key}`)}
              </div>
              <div className={'columnHeaderConfig-info-config-contentType'}>
                <div
                  className={'columnHeaderConfig-info-config-contentType-icon'}
                >
                  {
                    columnHeaderMappingContentTypeIcons[
                      selectedMapping.contentType
                    ]
                  }
                </div>
                <div
                  className={'columnHeaderConfig-info-config-contentType-name'}
                >
                  {t(`contentType.${selectedMapping.contentType}`)}
                </div>
              </div>
              <div
                className={
                  'columnHeaderConfig-info-config-text global-textElement'
                }
              >
                {t(`descriptions.${selectedMapping.key}`)}
              </div>
              <MappingOptions
                mapping={selectedMapping}
                setMapping={(m) => setMapping(m, selectedMappingIndex)}
                sheetData={sheetData}
              />
              {selectedMapping.previewFunc && !!selectedMapping.mappedKey ? (
                <div className={'columnHeaderConfig-info-config-preview'}>
                  <div
                    className={'columnHeaderConfig-info-config-preview-title'}
                  >
                    {t('preview')}
                  </div>
                  <div
                    className={'columnHeaderConfig-info-config-preview-values'}
                  >
                    {selectedMapping.previewFunc(
                      selectedMapping,
                      getExampleValues(selectedMapping.mappedKey)
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            <div className={'columnHeaderConfig-info-examples'}>
              <div className={'columnHeaderConfig-info-examples-title'}>
                {t('examples')}
              </div>
              <div className={'columnHeaderConfig-info-examples-values'}>
                {!!selectedMapping.mappedKey ? (
                  getExampleValues(selectedMapping.mappedKey, true).map(
                    (value) => (
                      <div className={'columnHeaderConfig-info-examples-value'}>
                        {value}
                      </div>
                    )
                  )
                ) : (
                  <div
                    className={'columnHeaderConfig-info-examples-values-empty'}
                  >
                    {t('exampleValues_empty')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={'global-cardActions global-cardActions-margin'}>
          <Button
            cta={t('cta')}
            width={'minimal'}
            look={'save'}
            action={() => mapData(mappings, sheetData, setMappings)}
            active={isConfigValid()}
          />
        </div>
      </>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default ColumnHeaderConfig;
