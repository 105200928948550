import './stockperproductlist.css';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import List from '../../features/list/List';
import StocksPerProductObject, {
  WarehouseStock,
} from '../../types/StocksPerProductObject';
import useWarehouseColors from '../../hooks/useWarehouseColors';
import useListControlsSearch from '../../features/list/listcontrols/hooks/useListControlsSearch';
import useListRenderObjects from '../../hooks/list/useListRenderObjects';
import useListTools from '../../hooks/list/useListTools';
import Button from '../../elements/button/Button';

interface StockPerProductListProps {
  productStocks: StocksPerProductObject[];
}

const StockPerProductList: React.FC<StockPerProductListProps> = ({
  productStocks,
}) => {
  const { t } = useTranslation('translations', { keyPrefix: 'view.logistics' });
  const getWarehouseColor = useWarehouseColors();
  const { listControlSearch, currentItems, query } = useListControlsSearch(
    t('search'),
    productStocks,
    ['manufacturerProductNumber', 'ean', 'productNumber', 'productName']
  );
  const [selectedItems, setSelectedItems] = useState<StocksPerProductObject[]>(
    []
  );
  const { toggleItem, toggleAllItems, selectTranche } =
    useListTools<StocksPerProductObject>();
  const { renderLimitedString } = useListRenderObjects();

  const renderStockTotal = useCallback((stock: number) => {
    console.log(stock);
    return (
      <div
        style={{
          color:
            stock > 10
              ? 'var(--color-success)'
              : stock > 0
              ? 'var(--color-yellow)'
              : 'var(--color-danger)',
        }}
      >
        {stock}
      </div>
    );
  }, []);

  const renderWarehouses = useCallback((warehouses: WarehouseStock[]) => {
    return (
      <div className="stockperproductlist-listItem">
        {warehouses.map((warehouse) => {
          return (
            <div className="stockperproductlist-listItem-warehouse">
              <div
                className={'stockperproductlist-listItem-warehouse-bar'}
                style={{
                  backgroundColor: getWarehouseColor(warehouse.name),
                }}
              ></div>
              <div className="stockperproductlist-listItem-warehouse-stock">
                <span className="stockperproductlist-listItem-warehouse-stock-quantity">
                  <span className="stockperproductlist-listItem-warehouse-stock-quantity">
                    {warehouse.stock}
                    <span className="stockperproductlist-listItem-warehouse-stock-label">
                      {t('listItem.stock')}
                    </span>
                  </span>
                </span>
              </div>
              <div
                className="stockperproductlist-listItem-warehouse-name"
                style={{
                  color: getWarehouseColor(warehouse.name),
                }}
              >
                {warehouse.name}
              </div>
            </div>
          );
        })}
      </div>
    );
  }, []);

  const openSelectedProductsInBulkEditor = (copy?: boolean) => {
    localStorage.setItem(
      'productBulkEditor_loadIds',
      JSON.stringify(selectedItems.map((x) => x.id))
    );
    window.open(`/productBulkEditor?loadIds=true${copy ? '&copy=true' : ''}`);
  };

  return (
    <div className="stockperproductlist">
      <List
        ignore={['productId']}
        pageItemCountOptions={[25, 50, 100]}
        items={currentItems}
        selectedItems={selectedItems}
        onSelect={(item) => setSelectedItems(toggleItem(selectedItems, item))}
        onSelectAll={(items) =>
          setSelectedItems(toggleAllItems(selectedItems, items))
        }
        onSelectTranche={(tranche) =>
          setSelectedItems(selectTranche(tranche, selectedItems))
        }
        listControls={{
          search: listControlSearch,
        }}
        renderObjects={[
          {
            key: 'productName',
            renderMethod: (name: string) =>
              renderLimitedString(name, 300, query),
          },
          {
            key: 'warehouses',
            renderMethod: renderWarehouses,
          },
          {
            key: 'totalStock',
            renderMethod: renderStockTotal,
            receiveNullValues: true,
          },
        ]}
        actionsBar={{
          buttons: [
            <Button
              cta={t('actions.editInBulk')}
              look={'secondary'}
              action={() => openSelectedProductsInBulkEditor(false)}
              width={'tiny'}
              margin={'right'}
            />,
          ],
        }}
        linkedKey={'productId'}
        linkPrefix={() => '/stocks/'}
        itemImgKey={'productCoverUri'}
        monoSpaceStrings={['productNumber', 'manufacturerProductNumber', 'ean']}
        queryString={query}
        queryKeys={['ean', 'productNumber', 'manufacturerProductNumber']}
        sortValueFunctions={{
          productName: (stock) => {
            return stock.productName.toLowerCase();
          },
        }}
        adjustHeightToViewport
        adjustHeightToViewportOffset={120}
        isShowingIndex
        tableHeadContrast
      />
    </div>
  );
};

export default StockPerProductList;
