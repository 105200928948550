import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigator, Stage } from '../../layout/stage/Stage';
import Button from '../../elements/button/Button';
import { ReactComponent as Export } from '../../../assets/icon/export.svg';
import Card, { CardSection } from '../../elements/card/Card';
import List from '../../features/list/List';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { Has } from '../../contexts/auth/Authorization';
import { LoadingContainer } from '../../elements/loading/Loading';
import { useNavigate } from 'react-router-dom';
import {
  ManufacturerResponse,
  SimpleManufacturerResponse,
} from '../../api/petcloudapi/api';
import useListControlsSearch from '../../features/list/listcontrols/hooks/useListControlsSearch';
import useListTools from '../../hooks/list/useListTools';
import Popup from '../../elements/popup/Popup';

interface MinifiedManufacturer {
  id: string;
  title: string;
  customerNumber: string;
  status: string;
  createdAt: string;
  logo?: string;
}

const Manufacturers = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.manufacturers',
  });
  const link = useNavigate();
  const { listControlSearch, query, currentItems, setOriginalItems } =
    useListControlsSearch<MinifiedManufacturer>(
      t('search'),
      [],
      ['title', 'customerNumber']
    );
  const [manufacturers, setManufacturers] = useState<
    MinifiedManufacturer[] | null
  >(null);

  const api = usePetCloudApi();
  const manufacturersApi = api.manufacturersApi();
  const { toggleItem, toggleAllItems } = useListTools<MinifiedManufacturer>();
  const [selectedManufacturers, setSelectedManufacturers] = useState<
    MinifiedManufacturer[]
  >([]);
  const [isLoadingUserEmails, setIsLoadingUserEmails] = useState(false);

  const batchPromises = (
    batchSize: number,
    items: (() => Promise<ManufacturerResponse>)[]
  ): Promise<ManufacturerResponse[]> => {
    const results: ManufacturerResponse[] = [];
    let index = 0;

    const executeNext = (): Promise<ManufacturerResponse[]> => {
      const batch = items
        .slice(index, index + batchSize)
        .map((p) => p().catch((e) => e));
      index += batchSize;
      if (batch.length) {
        return Promise.all(batch).then((res) => {
          results.push(...res);
          return executeNext();
        });
      } else {
        return Promise.resolve(results);
      }
    };

    return executeNext();
  };

  useEffect(() => {
    if (!manufacturers) {
      manufacturersApi
        .manufacturersGetSimpleManufacturers()
        .then((response) => {
          console.log(response.data);

          const minifiedManufacturers = response.data.map(
            (manufacturer: SimpleManufacturerResponse) => {
              return {
                id: manufacturer.id,
                logo: manufacturer.logoAsset?.uri,
                title: manufacturer.companyName,
                customerNumber: manufacturer.customerNumber,
                status: manufacturer.enabled ? 'Active' : 'Inactive',
                createdAt: manufacturer.createdAt,
              };
            }
          );
          setOriginalItems(minifiedManufacturers);
          setManufacturers(minifiedManufacturers);
        });
    }
  }, [manufacturers]);

  const getManufacturerById = (id: string): Promise<ManufacturerResponse> => {
    return new Promise((resolve, reject) => {
      manufacturersApi
        .manufacturersGetManufacturerById(id)
        .then((response) => {
          console.log(response.data);
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  };

  const emailBcc = () => {
    if (selectedManufacturers.length > 0) {
      // set popup loading state and fetch all users of selected manufacturers
      setIsLoadingUserEmails(true);
      const promises = selectedManufacturers.map((manufacturer) => {
        return () => getManufacturerById(manufacturer.id);
      });

      // get all email addresses and open mailto link
      batchPromises(10, promises)
        .then((responses) => {
          const emails: string[] = [];
          responses.forEach((m) =>
            m.users?.forEach((u) => {
              if (u.email) {
                emails.push(u.email);
              }
            })
          );

          if (emails.length > 0) {
            // Generate the mailto link
            // Open the default mail client with the mailto link
            window.location.href = `mailto:?bcc=${encodeURIComponent(
              emails.join(',')
            )}`;
          }
          setIsLoadingUserEmails(false);
        })
        .catch((error) => {
          setIsLoadingUserEmails(false);
        });
    }
  };

  if (manufacturers !== null) {
    return (
      <Stage>
        <Navigator title={t('title')}>
          <Button cta={t('export')} margin="left" type="icon-text">
            <Export
              className="button-icon"
              fill="var(--color-text_secondary)"
            />
          </Button>
          <Has authorizations={['manufacturers:create']}>
            <Button
              cta={t('new')}
              margin="left"
              look="primary"
              action={() => link('/manufacturers/new')}
            ></Button>
          </Has>
        </Navigator>
        <Card bigScreenWidth="100%">
          <Has authorizations={['manufacturers:list']}>
            <CardSection>
              <List
                items={currentItems}
                linkedKey="id"
                ignore={['id']}
                dateStrings={['createdAt']}
                adjustHeightToViewport
                adjustHeightToViewportOffset={80}
                monoSpaceStrings={['customerNumber']}
                badgeKeys={['status']}
                itemImgKey={'logo'}
                queryString={query}
                queryKeys={['title', 'customerNumber']}
                tableHeadContrast
                listControls={{
                  search: listControlSearch,
                }}
                selectedItems={selectedManufacturers}
                onSelect={(item: MinifiedManufacturer) => {
                  setSelectedManufacturers(
                    toggleItem(selectedManufacturers, item) ?? []
                  );
                }}
                onSelectAll={() =>
                  currentItems
                    ? setSelectedManufacturers(
                        toggleAllItems(selectedManufacturers, currentItems)
                      )
                    : undefined
                }
                actionsBar={{
                  buttons: [
                    <Button
                      cta={t('actions.emailBcc')}
                      look={'secondary'}
                      action={emailBcc}
                      width={'tiny'}
                      margin={'right'}
                    />,
                  ],
                }}
              />
            </CardSection>
          </Has>
        </Card>
        <Popup toggled={isLoadingUserEmails} width={'30%'} close={() => {}}>
          <LoadingContainer message={t('isLoadingUserEmails')} />
        </Popup>
      </Stage>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Manufacturers;
