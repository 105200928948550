import './formbuilder.css';
import Input, { InputProps } from '../input/Input';
import _ from 'lodash';
import DatePicker from '../datepicker/DatePicker';
import moment from 'moment';
import {
  Check,
  CheckProps,
  Dropdown,
  DropdownOption,
} from '../selectors/Selectors';
import DropdownMenu from '../dropdownmenu/DropdownMenu';
import Button from '../button/Button';
import { ReactComponent as IconPlus } from '../../../assets/icon/plus.svg';
import { ReactComponent as IconTrash } from '../../../assets/icon/trash.svg';
import ToggleSwitch from '../toggleswitch/ToggleSwitch';
import Loading from '../loading/Loading';

type BaseFormBuilderElement = {
  title?: string;
  objectPath?: string;
  //will be used for flex property e.g flex: 2
  size?: number;
};

type GroupElement = BaseFormBuilderElement & {
  type: 'group';
  title?: undefined;
  objectPath?: undefined;
  size?: undefined;
  children: FormBuilderElement[];
};

type NonGroupElement = BaseFormBuilderElement & {
  type:
    | 'input'
    | 'inputArray'
    | 'dropdown'
    | 'dropdownMenu'
    | 'checkbox'
    | 'toggle'
    | 'date';
  objectPath: NonNullable<BaseFormBuilderElement['objectPath']>;
};

type InputElement = NonGroupElement & {
  type: 'input' | 'inputArray';
  cleanupFunc?:
    | 'removeSpaces'
    | 'emptyStringToNull'
    | ((value: string) => string | null);
} & InputProps;

type DateElement = NonGroupElement & {
  type: 'date';
  title?: string;
  dateFormat: string;
  returnDateFormat?: string | 'UTC';
};

type CheckboxElement = NonGroupElement & {
  type: 'checkbox';
} & Omit<CheckProps, 'checked'>;

type ToggleElement = NonGroupElement & {
  type: 'toggle';
  label?: string;
  hint?: string;
  disabled?: boolean;
};

type DropdownElement = NonGroupElement & {
  type: 'dropdown' | 'dropdownMenu';
  options?: string[] | null;
  optionObjects?: DropdownOption[] | null;
  required?: boolean;
};

export type FormBuilderElement =
  | GroupElement
  | InputElement
  | DateElement
  | DropdownElement
  | CheckboxElement
  | ToggleElement;

interface FormBuilderProps {
  contentObject: any;
  setContentObject: (contentObject: any) => void;
  elements: FormBuilderElement[];
  helperCSSClass?: string;
}

const FormBuilder: React.FunctionComponent<FormBuilderProps> = ({
  contentObject,
  setContentObject,
  elements,
  helperCSSClass,
}) => {
  const renderDropdown = (element: DropdownElement, value: any) => {
    const { type, title, objectPath, optionObjects, options, required } =
      element;
    const selected = options
      ? value
      : optionObjects
      ? optionObjects.find((x) => x.id === value)?.name
      : undefined;

    if (type === 'dropdownMenu') {
      if (!options && !optionObjects) {
        return <Loading />;
      } else {
        return (
          <DropdownMenu
            title={title}
            options={options}
            optionObjects={optionObjects}
            selected={selected}
            onSelect={(value, id) => {
              if (options) {
                setContentObject({
                  ..._.set(contentObject, objectPath, value),
                });
              } else if (optionObjects && id) {
                setContentObject({
                  ..._.set(contentObject, objectPath, id),
                });
              }
            }}
            required={required}
          />
        );
      }
    } else if (type === 'dropdown') {
      if (!options && !optionObjects) {
        return <Loading />;
      } else {
        return (
          <Dropdown
            title={title}
            options={options}
            optionObjects={optionObjects}
            selected={selected}
            update={(e) => {
              if (options) {
                setContentObject({
                  ..._.set(
                    contentObject,
                    objectPath,
                    e.currentTarget.selectedOptions[0].value
                  ),
                });
              } else if (optionObjects) {
                const id =
                  e.currentTarget.selectedOptions[0].getAttribute('data-value');
                setContentObject({
                  ..._.set(contentObject, objectPath, id),
                });
              }
            }}
            required={required}
          />
        );
      }
    }
  };

  const renderInput = (
    element: InputElement,
    value: string,
    objectPathOverride?: string
  ) => {
    const { title, objectPath, errors, hint } = element;
    return (
      <Input
        title={title}
        content={value}
        update={(v) => {
          let output: string | null = v.trim();
          if (element.cleanupFunc) {
            if (element.cleanupFunc === 'removeSpaces') {
              output = removeSpaces(output);
            } else if (element.cleanupFunc === 'emptyStringToNull') {
              output = output !== '' ? output : null;
            } else {
              output = element.cleanupFunc(output);
            }
          }
          console.log(output);
          setContentObject({
            ..._.set(contentObject, objectPathOverride ?? objectPath, output),
          });
        }}
        helperCSSClass={element.helperCSSClass}
        errors={errors}
        required={element.required}
        hint={hint}
        withClipboard={element.withClipboard}
        disabled={element.disabled}
        prefix={element.prefix}
      />
    );
  };

  const renderInputArray = (element: InputElement, value: string[]) => {
    const { objectPath } = element;
    const nodes = value.map((val, index) => {
      return renderInput(element, val, `${objectPath}[${index}]`);
    });
    nodes.push(
      <div className={'formBuilder-inputArray-buttons'}>
        <Button
          type={'icon'}
          width={'tiny'}
          look={'secondary'}
          action={() => {
            const output = [...value, ''];
            setContentObject({
              ..._.set(contentObject, objectPath, output),
            });
          }}
        >
          <IconPlus className={'button-icon-tiny'} />
        </Button>
        {value.length > 0 ? (
          <Button
            type={'icon'}
            width={'tiny'}
            look={'secondary'}
            action={() => {
              const output = [...value];
              output.pop();
              setContentObject({
                ..._.set(contentObject, objectPath, output),
              });
            }}
          >
            <IconTrash className={'button-icon-tiny button-icon-danger'} />
          </Button>
        ) : null}
      </div>
    );
    return nodes;
  };

  const removeSpaces = (value: string) => {
    return value.replace(/\s/g, '');
  };

  const renderElement = (element: FormBuilderElement) => {
    const { objectPath, title, type } = element;
    const value = _.get(contentObject, objectPath ?? '');
    switch (type) {
      case 'input':
        return renderInput(element, value);
      case 'inputArray':
        return renderInputArray(element, value);
      case 'dropdown':
        return renderDropdown(element, value);
      case 'dropdownMenu':
        return renderDropdown(element, value);
      case 'date':
        return (
          <DatePicker
            title={title}
            selected={new Date(value)}
            dateFormat={element.dateFormat}
            onChange={(date) => {
              const returnFormat =
                element.returnDateFormat ?? element.dateFormat;
              const formatted =
                returnFormat !== 'ISO'
                  ? moment(date).format(returnFormat.toUpperCase())
                  : date?.toISOString();
              setContentObject({
                ..._.set(contentObject, objectPath, formatted),
              });
            }}
          />
        );
      case 'checkbox':
        return (
          <Check
            checked={value}
            hint={element.hint}
            text={title}
            update={() =>
              setContentObject({ ..._.set(contentObject, objectPath, !value) })
            }
            disabled={element.disabled}
          />
        );
      case 'toggle':
        return (
          <ToggleSwitch
            toggled={value}
            hint={element.hint}
            title={title}
            toggle={() =>
              setContentObject({ ..._.set(contentObject, objectPath, !value) })
            }
            label={element.label}
            disabled={element.disabled}
          />
        );
    }
  };

  return (
    <div className={`formBuilder ${helperCSSClass ?? ''}`}>
      {elements.map((element, index) => {
        const { type, size } = element;
        if (type === 'group' && element.children) {
          return (
            <div key={index} className="global-inputGroup">
              {element.children.map((el, i) => {
                return (
                  <div
                    className={'global-inputGroup-input'}
                    style={el.size ? { flex: el.size } : undefined}
                  >
                    {renderElement(el)}
                  </div>
                );
              })}
            </div>
          );
        } else {
          return (
            <div key={index} className="global-inputGroup">
              <div
                className={'global-inputGroup-input'}
                style={size ? { flex: size } : undefined}
              >
                {renderElement(element)}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default FormBuilder;
