import './sheetparser.css';
import useNotifications from '../../../../hooks/useNotifications';
import Papa from 'papaparse';
import { read, utils } from 'xlsx';
import { useTranslation } from 'react-i18next';
import Dropzone from '../../../../elements/dropzone/Dropzone';
import { useState } from 'react';
import Popup from '../../../../elements/popup/Popup';
import Button from '../../../../elements/button/Button';
import Input from '../../../../elements/input/Input';
import { SheetData } from '../ProductImporter';
import DropdownMenu from '../../../../elements/dropdownmenu/DropdownMenu';
import { Dropdown } from '../../../../elements/selectors/Selectors';
import { useUser } from '../../../../contexts/auth/User';
import useManufacturerOptions from '../../../../hooks/data/useManufacturerOptions';

interface SheetParserProps {
  setSheetData: (data: SheetData) => void;
}

enum SheetParserStep {
  ActionType,
  HeaderRowIndex,
  IdKeys,
  ProductIdMethod,
}

const SheetParser: React.FC<SheetParserProps> = ({ setSheetData }) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productBulkEditor.productImporter.sheetParser',
  });
  const { pushNotification } = useNotifications();
  const [step, setStep] = useState(SheetParserStep.ActionType);
  const [rawData, setRawData] = useState<any[] | null>(null);

  const { user } = useUser();
  const { manufacturerOptions } = useManufacturerOptions();
  const [selectedManufacturerId, setSelectedManufacturerId] = useState<
    string | null
  >(null);

  const [selectedActionType, setSelectedActionType] = useState<string | null>(
    null
  );
  const [headerRowIndex, setHeaderRowIndex] = useState(0);
  const [headerRowIndexOutOfBounds, setHeaderRowIndexOutOfBounds] =
    useState(false);
  const [idKey, setIdKey] = useState<string | null>(null);
  const [parentIdKey, setParentIdKey] = useState<string | null>(null);
  const [productIdMethod, setProductIdMethod] = useState<string | null>(null);
  const [productIdKeyMappedKey, setProductIdKeyMappedKey] = useState<
    string | null
  >(null);

  const onUpload = (files: File[]) => {
    const file = files[0];
    const fileType = file.type;
    if (fileType === 'text/csv') {
      parseCSV(file);
    } else if (
      fileType ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      parseXLSX(file);
    } else {
      pushNotification(t('notifications.fileType_invalid'), 'warning');
    }
  };

  const parseCSV = (file: File) => {
    Papa.parse(file, {
      complete: (results) => {
        console.log(results.data);
        setRawData(results.data);
      },
    });
  };

  const parseXLSX = async (file: File) => {
    const f = await file.arrayBuffer();
    const wb = read(f, { type: 'array' });
    const ws = wb.Sheets[wb.SheetNames[0]];
    let data = utils.sheet_to_json(ws, {
      header: 1,
      blankrows: false,
    });

    // Convert numeric values to string
    data = data.map((row: any) =>
      row.map((item: any) =>
        typeof item === 'number' ? item.toString() : item
      )
    );

    console.log(data);
    setRawData(data);
  };

  const prepareSheetData = () => {
    if (rawData) {
      setSheetData({
        data: rawData.slice(headerRowIndex + 1),
        headers: rawData[headerRowIndex],
        idKey: idKey ?? undefined,
        parentIdKey: parentIdKey ?? undefined,
        productIdKey: productIdMethod ?? undefined,
        productIdKeyMappedKey: productIdKeyMappedKey ?? undefined,
        manufacturerId: selectedManufacturerId ?? undefined,
      });
    }
  };

  const actionTypeOptions = [
    {
      id: 'import',
      name: t('actionType.options.import'),
    },
    { id: 'update', name: t('actionType.options.update') },
  ];

  const idKeyOptions = [
    {
      id: 'id',
      name: t('idMethod.options.id'),
    },
    {
      id: 'ean',
      name: t('idMethod.options.ean'),
    },
    {
      id: 'manufacturerProductNumber',
      name: t('idMethod.options.manufacturerProductNumber'),
    },
    {
      id: 'productNumber',
      name: t('idMethod.options.productNumber'),
    },
  ];

  const renderStep = (rawData: any[]) => {
    switch (step) {
      case SheetParserStep.ActionType:
        return (
          <>
            <div className={'popup-title'}>{t('actionType.title')}</div>
            <div className={'global-textElement sheetParser-uploadConfirmText'}>
              {t('actionType.text')}
            </div>
            <div className={'global-inputGroup'}>
              <div className={'global-inputGroup-input'}>
                <Dropdown
                  title={t('actionType.options.title')}
                  optionObjects={actionTypeOptions}
                  selected={
                    actionTypeOptions.find((x) => x.id === selectedActionType)
                      ?.name
                  }
                  update={(e) => {
                    const id =
                      e.target.selectedOptions[0].getAttribute('data-value');
                    if (id) setSelectedActionType(id);
                  }}
                />
              </div>
            </div>
            <div className={'global-cardActions'}>
              <Button
                cta={t('actionType.cta')}
                action={() => setStep(SheetParserStep.HeaderRowIndex)}
                look={'secondary'}
                width={'minimal'}
                active={selectedActionType !== null}
              />
            </div>
          </>
        );
      case SheetParserStep.HeaderRowIndex:
        return (
          <>
            <div className={'popup-title'}>{t('uploadConfirm.title')}</div>
            <div className={'global-textElement sheetParser-uploadConfirmText'}>
              {t('uploadConfirm.text_1')}
              <span>{`${rawData.length} ${t('uploadConfirm.rows')}`}</span>
              {t('uploadConfirm.text_2')}
              <span>{`${rawData[headerRowIndex].length} ${t(
                'uploadConfirm.columns'
              )}`}</span>
              {t('uploadConfirm.text_3')}
            </div>
            <div className={'global-inputGroup'}>
              <div className={'global-inputGroup-input'}>
                <Input
                  title={t('uploadConfirm.headerRowIndex')}
                  content={(headerRowIndex + 1).toString()}
                  update={(v) => {
                    const n = parseInt(v);
                    if (n <= rawData.length) {
                      setHeaderRowIndex(n - 1);
                      setHeaderRowIndexOutOfBounds(false);
                    } else {
                      setHeaderRowIndexOutOfBounds(true);
                    }
                  }}
                  type={'number'}
                  maxValue={rawData.length}
                  unit={'#'}
                  hint={t('uploadConfirm.headerRowIndex_hint')}
                  errors={
                    headerRowIndexOutOfBounds
                      ? [t('notifications.headerRowIndexOutOfBounds')]
                      : undefined
                  }
                />
              </div>
            </div>
            <div className={'sheetParser-exampleHeaders-title'}>
              {t('exampleHeaders')}
            </div>
            <div className={'sheetParser-exampleHeaders'}>
              {rawData[headerRowIndex].map((h: string, index: number) => {
                if (index <= 9) {
                  return (
                    <div key={index} className={'sheetParser-exampleHeader'}>
                      {h}
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <div className={'global-cardActions'}>
              <Button
                cta={t('uploadConfirm.cta')}
                action={() => {
                  if (selectedActionType === 'import') {
                    setStep(SheetParserStep.IdKeys);
                  } else {
                    setStep(SheetParserStep.ProductIdMethod);
                  }
                }}
                look={'secondary'}
                width={'minimal'}
                active={!headerRowIndexOutOfBounds}
              />
            </div>
          </>
        );
      case SheetParserStep.IdKeys:
        return (
          <>
            <div className={'popup-title'}>{t('idKeys.title')}</div>
            <div className={'global-textElement sheetParser-idKeyMappingText'}>
              {t(`idKeys.text`)}
            </div>
            <div className={'global-inputGroup'}>
              <div className={'global-inputGroup-input'}>
                <DropdownMenu
                  title={t('idKeys.idKey')}
                  options={[...rawData[headerRowIndex]]}
                  selected={idKey}
                  onSelect={(v) => {
                    setIdKey(v);
                  }}
                />
              </div>
              <div className={'global-inputGroup-input'}>
                <DropdownMenu
                  title={t('idKeys.parentIdKey')}
                  options={[...rawData[headerRowIndex]]}
                  selected={parentIdKey}
                  onSelect={(v) => {
                    setParentIdKey(v);
                  }}
                />
              </div>
            </div>
            <div
              className={
                'global-cardActions-spaceBetween global-cardActions-margin'
              }
            >
              <Button
                cta={t('idKeys.cta.back')}
                action={() => setStep(SheetParserStep.HeaderRowIndex)}
                look={'secondary'}
                width={'minimal'}
              />
              <Button
                cta={
                  idKey && parentIdKey
                    ? t('idKeys.cta.confirm')
                    : t('idKeys.cta.skip')
                }
                action={prepareSheetData}
                look={idKey && parentIdKey ? 'save' : 'secondary'}
                width={'minimal'}
              />
            </div>
          </>
        );

      case SheetParserStep.ProductIdMethod:
        return (
          <>
            <div className={'popup-title'}>{t('idMethod.title')}</div>
            <div className={'global-textElement sheetParser-idKeyMappingText'}>
              {t(`idMethod.text`)}
            </div>
            <div className={'global-inputGroup'}>
              <div className={'global-inputGroup-input'}>
                <DropdownMenu
                  title={t('idMethod.idMethod')}
                  hint={t('idMethod.idMethod_hint')}
                  optionObjects={idKeyOptions}
                  selected={
                    idKeyOptions.find((x) => x.id === productIdMethod)?.name
                  }
                  onSelect={(value, id) => {
                    if (id) setProductIdMethod(id);
                  }}
                  disableSearch
                />
              </div>
            </div>
            {user?.isProductOwner &&
            manufacturerOptions &&
            manufacturerOptions !== 'NO_PERMISSION' &&
            productIdMethod === 'manufacturerProductNumber' ? (
              <div className={'global-inputGroup'}>
                <div className={'global-inputGroup-input'}>
                  <DropdownMenu
                    title={t('idMethod.manufacturerId')}
                    optionObjects={manufacturerOptions}
                    selected={
                      manufacturerOptions.find(
                        (x) => x.id === selectedManufacturerId
                      )?.name
                    }
                    onSelect={(v, id) => {
                      if (id) setSelectedManufacturerId(id);
                    }}
                  />
                </div>
              </div>
            ) : null}
            <div className={'global-inputGroup'}>
              <div className={'global-inputGroup-input'}>
                <DropdownMenu
                  title={t('idMethod.mappedKey')}
                  hint={t('idMethod.mappedKey_hint')}
                  options={[...rawData[headerRowIndex]]}
                  selected={productIdKeyMappedKey}
                  onSelect={(key) => {
                    if (key) setProductIdKeyMappedKey(key);
                  }}
                />
              </div>
            </div>
            <div
              className={
                'global-cardActions-spaceBetween global-cardActions-margin'
              }
            >
              <Button
                cta={t('idMethod.cta.back')}
                action={() => setStep(SheetParserStep.HeaderRowIndex)}
                look={'secondary'}
                width={'minimal'}
              />
              <Button
                cta={t('idMethod.cta.confirm')}
                action={prepareSheetData}
                look={'save'}
                width={'minimal'}
                active={productIdMethod !== null}
              />
            </div>
          </>
        );
    }
  };

  return (
    <div className={'sheetParser'}>
      <Dropzone maxFiles={1} height={'100%'} callbackWithoutUpload={onUpload} />
      {rawData ? (
        <Popup
          toggled={true}
          width={'20%'}
          close={() => {
            setRawData(null);
            setSelectedActionType(null);
            setStep(SheetParserStep.ActionType);
          }}
        >
          {renderStep(rawData)}
        </Popup>
      ) : null}
    </div>
  );
};

export default SheetParser;
