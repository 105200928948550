import { useTranslation } from 'react-i18next';
import { Has } from '../../contexts/auth/Authorization';
import { Navigator, Stage } from '../../layout/stage/Stage';
import { usePetCloudApi } from '../../api/PetCloudApi';
import { useErrorHandler } from '../../contexts/errorhandler/ErrorHandler';
import { useEffect, useState } from 'react';
import { IntegrationResponse } from '../../api/petcloudapi/api';
import { LoadingContainer } from '../../elements/loading/Loading';
import IntegrationCard from '../../sections/integration/integrationcard/IntegrationCard';
import useManufacturerOptions from '../../hooks/data/useManufacturerOptions';
import DropdownMenu from '../../elements/dropdownmenu/DropdownMenu';
import { InlineCards } from '../../elements/card/Card';

const Integrations = () => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.integrations',
  });
  const api = usePetCloudApi();
  const integrationsApi = api.integrationsApi();
  const errorHandler = useErrorHandler();
  const { manufacturerOptions } = useManufacturerOptions();

  const [selectedManufacturerId, setSelectedManufacturerId] = useState<
    string | null
  >(null);
  const [integrations, setIntegrations] = useState<
    IntegrationResponse[] | null
  >(null);

  useEffect(() => {
    getIntegrations();
  }, [selectedManufacturerId]);

  const getIntegrations = () => {
    integrationsApi
      .integrationsGetIntegrations(selectedManufacturerId ?? undefined)
      .then((response) => {
        console.log(response);
        setIntegrations(response.data);
      })
      .catch((error) => {
        console.log(error);
        errorHandler.addError(error.response);
      });
  };

  if (integrations) {
    return (
      <Has authorizations={['integrations_api:list']} showNoAuthorization>
        <Stage>
          <Navigator title={t('title')}>
            {manufacturerOptions && manufacturerOptions !== 'NO_PERMISSION' ? (
              <DropdownMenu
                optionObjects={manufacturerOptions}
                selected={
                  manufacturerOptions.find(
                    (x) => x.id === selectedManufacturerId
                  )?.name
                }
                onSelect={(value, id) => setSelectedManufacturerId(id!)}
              />
            ) : null}
          </Navigator>
          <InlineCards>
            {integrations.map((integration) => (
              <IntegrationCard
                key={integration.identifier}
                integration={integration}
                manufacturerId={selectedManufacturerId}
              />
            ))}
          </InlineCards>
        </Stage>
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default Integrations;
