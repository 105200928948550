import {
  BulkEditorProduct,
  BulkEditorProductMode,
  getEmptyBulkEditorProduct,
} from '../../../../view/products/BulkEditor';
import { ColumnHeaderMappingType } from '../columnheaderconfig/hooks/useDefaultMappings';
import useNotifications from '../../../../hooks/useNotifications';
import { SheetData } from '../ProductImporter';
import { useTranslation } from 'react-i18next';
import { usePetCloudApi } from '../../../../api/PetCloudApi';
import {
  ProductIdentifierType,
  ProductResponse,
} from '../../../../api/petcloudapi/api';
import { useErrorHandler } from '../../../../contexts/errorhandler/ErrorHandler';
import usePromiseBatcher from '../../utils/usePromiseBatcher';

const useDataMapper = (
  setProductsCallback?: (products: BulkEditorProduct[]) => void
) => {
  const { pushNotification } = useNotifications();
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.productBulkEditor.productImporter.dataMapper',
  });
  const api = usePetCloudApi();
  const productsApi = api.productsApi();
  const errorHandler = useErrorHandler();
  const {
    loadProgress,
    resetLoadProgress,
    incrementLoadProgress,
    batchPromises,
  } = usePromiseBatcher<ProductResponse>();

  const getProductByIdentifier = (
    productIdentifierType: ProductIdentifierType,
    value: string,
    manufacturerId?: string
  ) => {
    return new Promise<ProductResponse>((resolve, reject) => {
      productsApi
        .productsGetProductByIdentifier(
          productIdentifierType,
          value,
          manufacturerId
        )
        .then((response) => {
          resolve(response.data);
          incrementLoadProgress();
        })
        .catch((error) => {
          errorHandler.addError(error.response);
          reject();
        });
    });
  };

  const fetchExistingProducts = async (sheetData: SheetData) => {
    // first get the id values from the sheet data
    const { productIdKeyMappedKey, productIdKey, manufacturerId } = sheetData;
    if (productIdKeyMappedKey === undefined) {
      pushNotification(t('notifications.no_id_key_mapped'), 'danger');
      throw new Error(t('notifications.no_id_key_mapped'));
    }
    if (productIdKey === undefined) {
      pushNotification(t('notifications.no_id_key'), 'danger');
      throw new Error(t('notifications.no_id_key'));
    }
    const keyIndex = sheetData.headers.findIndex(
      (x) => x.toLowerCase() === productIdKeyMappedKey.toLowerCase()
    );
    const ids = sheetData.data.map((row) => row[keyIndex]?.toString());
    const productIdentifierType = idKeyMap[productIdKey];

    // then create promises for each of them
    const promises = ids.map((id) => {
      return () =>
        getProductByIdentifier(productIdentifierType, id, manufacturerId);
    });

    resetLoadProgress(promises.length, t('loadingProducts'));

    // batch the promises and return the products
    return await batchPromises(10, promises);
  };

  const mapData = (
    mappings: ColumnHeaderMappingType[],
    sheetData: SheetData,
    setMappingsCallback?: (mappings: ColumnHeaderMappingType[]) => void,
    fetchedProducts?: ProductResponse[]
  ) => {
    console.log('fetched products', fetchedProducts);

    // only consider mappings that have a mapped key
    const selectedMappings = mappings.filter((x) => x.mappedKey !== undefined);

    // check if there are any errors within the configuration
    const updatedMappings = mappings.map((mapping) => {
      if (selectedMappings.includes(mapping) && mapping.validationFunc) {
        mapping.errors = mapping.validationFunc(mapping);
        return mapping;
      } else {
        return mapping;
      }
    });

    // if there are any errors, stop the process and alert the user
    if (updatedMappings.some((x) => x.errors)) {
      if (setMappingsCallback) setMappingsCallback(updatedMappings);
      pushNotification(t('notifications.mapping_errors_found'), 'danger');
      return;
    }

    const parentIdKeyIndex = sheetData.parentIdKey
      ? sheetData.headers.indexOf(sheetData.parentIdKey)
      : null;
    const idKeyIndex = sheetData.idKey
      ? sheetData.headers.indexOf(sheetData.idKey)
      : null;

    // import data and check if there are any errors while importing
    let hasErrors = false;
    const updatedMappings2 = [...mappings];
    const products: BulkEditorProduct[] = sheetData.data.map((row) => {
      // initialize an empty product
      let product: BulkEditorProduct = { ...getEmptyBulkEditorProduct() };

      // if the sheetData has been configured to update existing products,
      // find the product with the matching id value and use its data
      const { productIdKeyMappedKey, productIdKey } = sheetData;
      if (productIdKey && productIdKeyMappedKey && fetchedProducts) {
        const productIdKeyIndex = sheetData.headers.indexOf(
          productIdKeyMappedKey
        );
        const idValue = row[productIdKeyIndex]?.toString();
        const fetchedData = fetchedProducts.find(
          (x) =>
            x[productIdKey as keyof ProductResponse]?.toString() === idValue
        );
        if (fetchedData) {
          product = { ...fetchedData, mode: BulkEditorProductMode.Update };
        }
      }

      selectedMappings.forEach((mapping) => {
        const importResult = mapping.importFunc(
          product,
          row,
          mapping.mappedKey
            ? sheetData.headers.indexOf(mapping.mappedKey)
            : undefined,
          mapping.options,
          sheetData.headers
        );

        // if the import function returned an error, add the error to the mappings
        if ('identifier' in importResult) {
          const mappingIndex = updatedMappings2.findIndex(
            (x) => x.key === mapping.key
          );
          if (mappingIndex > -1) {
            const errors = updatedMappings2[mappingIndex].errors ?? [];
            errors.push(importResult);
            updatedMappings2[mappingIndex].errors = errors;
            hasErrors = true;
          }
          // otherwise add the data to the product
        } else {
          product = mapping.importFunc(
            product,
            row,
            mapping.mappedKey
              ? sheetData.headers.indexOf(mapping.mappedKey)
              : undefined,
            mapping.options,
            sheetData.headers
          ) as BulkEditorProduct;
        }
      });
      if (parentIdKeyIndex && idKeyIndex) {
        product.parentIdKeyValue = row[parentIdKeyIndex];
        product.idKeyValue = row[idKeyIndex];
      }
      return product;
    });

    // sets parentIds according to import config for creation of parent / child groups.
    if (parentIdKeyIndex && idKeyIndex) {
      products.forEach((product) => {
        product.parentId = products.find(
          (p) => p.idKeyValue === product.parentIdKeyValue
        )?.id;
      });

      // removes attributes that are no longer needed
      products.forEach((p) => {
        p.parentIdKeyValue = undefined;
        p.idKeyValue = undefined;
      });
    }

    if (!hasErrors) {
      if (setProductsCallback) setProductsCallback(products);
    } else {
      if (setMappingsCallback) setMappingsCallback(updatedMappings2);
      pushNotification(t('notifications.import_errors_found'), 'danger');
    }
  };

  return {
    mapData,
    fetchExistingProducts,
    loadProgress,
  };
};

export default useDataMapper;

const idKeyMap: { [key: string]: ProductIdentifierType } = {
  id: ProductIdentifierType.Id,
  ean: ProductIdentifierType.Ean,
  manufacturerProductNumber: ProductIdentifierType.ManufacturerProductNumber,
  productNumber: ProductIdentifierType.ProductNumber,
};
