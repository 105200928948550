import {
  AnimalSpeciesResponse,
  ProductGroupResponse,
  SimpleManufacturerResponse,
  TranslatedTypeResponseOfString,
} from '../../api/petcloudapi/api';
import Badge from '../../elements/badge/Badge';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../elements/input/Input';
import { SmallHint } from '../../elements/hint/Hint';
import { i18n } from 'i18next';
import TranslatedStringIndex from '../../types/TranslatedStringIndex';
import { displayStringHighlighted } from '../../elements/productsearch/ProductSearch';
import ValueComparison from '../../elements/valuecomparison/ValueComparison';
import { ReactComponent as IconImg } from '../../../assets/icon/image.svg';
import useManufacturerOptions from '../data/useManufacturerOptions';

type InputOptions = {
  type?: string;
  minValue?: string | number;
  maxValue?: string | number;
};

const useListRenderObjects = () => {
  const { t } = useTranslation('translations', { keyPrefix: 'hooks' });
  const { manufacturerDict } = useManufacturerOptions();

  const renderAnimalSpecies = useCallback(
    (animalSpecies: AnimalSpeciesResponse[] | null | undefined) => {
      if (animalSpecies && animalSpecies.length > 0) {
        return (
          <Badge
            title={animalSpecies.length.toString()}
            color={'var(--color-blue)'}
          />
        );
      } else {
        return <div className={'list-table-td-na'}>-</div>;
      }
    },
    []
  );

  const renderBoolean = useCallback((bool: boolean) => {
    return (
      <Badge
        title={
          bool
            ? t('useListRenderObjects.renderBoolean.true')
            : t('useListRenderObjects.renderBoolean.false')
        }
        color={bool ? 'var(--color-success)' : 'var(--color-danger)'}
      />
    );
  }, []);

  const renderArrayCount = useCallback((array: any[]) => {
    if (array.length > 0) {
      return (
        <Badge title={array.length.toString()} color={'var(--color-blue)'} />
      );
    } else {
      return <div className={'list-table-td-na'}>-</div>;
    }
  }, []);

  const renderInput = useCallback(
    (value: any, onUpdate: (newValue: any) => void, options?: InputOptions) => {
      return (
        <div className={'list-table-td-input'}>
          <Input
            content={value}
            update={(value) => onUpdate(value)}
            minValue={options?.minValue}
            maxValue={options?.maxValue}
            type={options?.type}
          />
        </div>
      );
    },
    []
  );

  const renderStringInput = (
    value: any,
    data: any,
    setData: any,
    item: ProductGroupResponse,
    key: string,
    helperCSSClass?: string
  ) => {
    return (
      <Input
        content={value}
        update={(e) => {
          const update = [...data];
          const i = update.findIndex((x) => x.id === item.id);
          if (i !== -1) {
            update[i] = { ...update[i], [key]: e };
            setData(update);
          }
        }}
        updateRequiresConfirmation
        minWidth={160}
        helperCSSClass={helperCSSClass}
      />
    );
  };

  const renderTranslatedStringInput = (
    value: TranslatedTypeResponseOfString,
    selectedLocale: TranslatedStringIndex,
    data: any,
    setData: (data: any) => void,
    item: any,
    key: string,
    helperCSSClass?: string
  ) => {
    return (
      <Input
        content={value[selectedLocale]}
        update={(e) => {
          console.log(item);
          const update = [...data];
          const i = update.findIndex((x) => x.id === item.id);
          if (i !== -1) {
            update[i] = {
              ...update[i],
              [key]: {
                ...update[i][key],
                [selectedLocale]: e,
              },
            };
            setData(update);
          }
        }}
        updateRequiresConfirmation
        minWidth={160}
        helperCSSClass={helperCSSClass}
      />
    );
  };

  const renderLimitedString = useCallback(
    (value: any, maxWidth: number | string, query?: string) => {
      if (value) {
        return (
          <SmallHint paragraphs={[value]} isToolTip delay>
            <div
              className={'list-table-td list-table-td-limited'}
              style={{ maxWidth: maxWidth }}
            >
              {displayStringHighlighted(value, query)}
            </div>
          </SmallHint>
        );
      } else {
        return <div className={'list-table-td-na'}>-</div>;
      }
    },
    []
  );

  const renderTranslatedLimitedString = (
    value: TranslatedTypeResponseOfString,
    i18n: i18n,
    maxWidth: number,
    query?: string
  ) => {
    return renderLimitedString(
      value[i18n.language as TranslatedStringIndex],
      maxWidth,
      query
    );
  };

  const renderPercentageBar = (value: number) => {
    return (
      <div className={'list-table-td-percentage'}>
        <div className={'list-table-td-percentage-value'}>{value}%</div>
        <div className={'list-table-td-percentage-bar'}>
          <div
            className={'list-table-td-percentage-bar-fill'}
            style={{ width: `${value}%` }}
          ></div>
        </div>
      </div>
    );
  };

  const renderValueComparison = (value: number, compareAtValue: number) => {
    return <ValueComparison value={value} compareValue={compareAtValue} />;
  };

  const renderManufacturer = useCallback(
    (manufacturer: SimpleManufacturerResponse | string) => {
      let m;
      if (
        typeof manufacturer === 'string' &&
        manufacturerDict &&
        manufacturerDict !== 'NO_PERMISSION'
      ) {
        m = manufacturerDict[manufacturer];
      } else {
        m = manufacturer as SimpleManufacturerResponse;
      }
      if (m) {
        return (
          <div className={'orderList-manufacturerInfo'}>
            {m.logoAsset?.uri ? (
              <img
                className={'orderList-manufacturerInfo-portrait'}
                src={m.logoAsset.uri}
                alt={'logo'}
              />
            ) : (
              <IconImg
                className={'orderList-manufacturerInfo-portrait'}
                fill={'var(--color-background)'}
              />
            )}
            <div className={'orderList-manufacturerInfo-name'}>
              {m.companyName}
            </div>
          </div>
        );
      } else {
        return <div>manufacturer missing</div>;
      }
    },
    [manufacturerDict]
  );

  return {
    renderAnimalSpecies,
    renderBoolean,
    renderArrayCount,
    renderInput,
    renderStringInput,
    renderTranslatedStringInput,
    renderLimitedString,
    renderTranslatedLimitedString,
    renderPercentageBar,
    renderValueComparison,
    renderManufacturer,
  };
};

export default useListRenderObjects;
