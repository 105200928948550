import axios from 'axios';
import { createContext, useContext } from 'react';
import { Cookies } from 'react-cookie';
import {
  AdyenAccountHolderApi,
  AdyenBalanceAccountsApi,
  AdyenDocumentsApi,
  AdyenGeneralApi,
  AdyenLegalEntitiesApi,
  AdyenNotificationsApi,
  AdyenPaymentRefundsApi,
  AdyenPaymentSplitsApi,
  AdyenTransferInstrumentsApi,
  AnimalSpeciesApi,
  AssetFoldersApi,
  AssetsApi,
  BrandContactAddressesApi,
  BrandsApi,
  BrandStoresApi,
  BulkEditorHistoryApi,
  BulkEditorSessionApi,
  CountriesApi,
  CurrenciesApi,
  CustomersApi,
  DashboardsApi,
  DataImportConfigsApi,
  DataImportsApi,
  DeliveryTimesApi,
  FiegePurchaseOrdersApi,
  InformationGroupsApi,
  IntegrationsApi,
  LoyaltyPointProcessingConfigsApi,
  LoyaltyPointTransactionsApi,
  LoyaltyRanksApi,
  ManufacturerConditionsApi,
  ManufacturerContactsApi,
  ManufacturerDataExchangesApi,
  ManufacturerMonthlyInvoicesApi,
  ManufacturersApi,
  ManufacturerSettingsApi,
  OrderInvoiceAddressesApi,
  OrderPaymentTransactionsApi,
  OrderReceiptsApi,
  OrderReturnAnnouncementsApi,
  OrderReturnsApi,
  OrdersApi,
  OrderShippingAddressesApi,
  OrderShippingDetailsApi,
  OrderStateHistoriesApi,
  PaymentTransactionsApi,
  PoliciesApi,
  ProductAdditionalInformationApi,
  ProductAssetsApi,
  ProductBundlesApi,
  ProductCategoriesApi,
  ProductGroupMappingsApi,
  ProductGroupsApi,
  ProductPricesApi,
  ProductsApi,
  ProductStocksApi,
  ProductUnitsApi,
  ProductVersionsApi,
  PromotionsApi,
  PropertyGroupsApi,
  PropertyOptionsApi,
  PropertyValidationRulesApi,
  ShippingProvidersApi,
  TagsApi,
  TaxTypesApi,
  UserRolesApi,
  UsersApi,
  WarehousesApi,
} from '../api/petcloudapi/api';
import { Configuration } from '../api/petcloudapi/configuration';
import { useAuthentication } from '../contexts/auth/Authentication';
import {
  IntegrationLoggingApi as ShopifyIntegrationLoggingApi,
  ShopifyDeliveryInformationApi,
  ShopifyIntegrationApi,
  ShopifyOrderExportApi,
  ShopifyProductImportApi,
  ShopifyProductStocksApi,
  ShopifyAppApi,
} from './shopifyapi';
import {
  IntegrationLoggingApi as ShopwareIntegrationLoggingApi,
  ShopwareDeliveryInformationApi,
  ShopwareOrderExportApi,
  ShopwareProductImportApi,
  ShopwareProductStocksApi,
  ShopwareIntegrationApi,
} from './shopwareapi';
import {
  IntegrationLoggingApi as MagentoIntegrationLoggingApi,
  MagentoDeliveryInformationApi,
  MagentoOrderExportApi,
  MagentoProductImportApi,
  MagentoProductStocksApi,
  MagentoIntegrationApi,
} from './magentoapi';

type PetCloudApiContextValue = {
  adyenAccountHolderApi: () => AdyenAccountHolderApi;
  adyenBalanceAccountsApi: () => AdyenBalanceAccountsApi;
  adyenDocumentsApi: () => AdyenDocumentsApi;
  adyenGeneralApi: () => AdyenGeneralApi;
  adyenLegalEntitiesApi: () => AdyenLegalEntitiesApi;
  adyenNotificationsApi: () => AdyenNotificationsApi;
  adyenPaymentRefundsApi: () => AdyenPaymentRefundsApi;
  adyenPaymentSplitsApi: () => AdyenPaymentSplitsApi;
  adyenTransferInstrumentsApi: () => AdyenTransferInstrumentsApi;
  animalSpeciesApi: () => AnimalSpeciesApi;
  assetsApi: () => AssetsApi;
  assetFoldersApi: () => AssetFoldersApi;
  brandContactAddressesApi: () => BrandContactAddressesApi;
  brandsApi: () => BrandsApi;
  bulkEditorHistoryApi: () => BulkEditorHistoryApi;
  bulkEditorSessionApi: () => BulkEditorSessionApi;
  brandStoresApi: () => BrandStoresApi;
  countriesApi: () => CountriesApi;
  currenciesApi: () => CurrenciesApi;
  customersApi: () => CustomersApi;
  dashboardsApi: () => DashboardsApi;
  dataImportConfigsApi: () => DataImportConfigsApi;
  dataImportsApi: () => DataImportsApi;
  deliveryTimesApi: () => DeliveryTimesApi;
  fiegePurchaseOrdersApi: () => FiegePurchaseOrdersApi;
  informationGroupsApi: () => InformationGroupsApi;
  integrationsApi: () => IntegrationsApi;
  loyaltyPointProcessingConfigsApi: () => LoyaltyPointProcessingConfigsApi;
  loyaltyPointTransactionsApi: () => LoyaltyPointTransactionsApi;
  loyaltyRanksApi: () => LoyaltyRanksApi;
  manufacturerConditionsApi: () => ManufacturerConditionsApi;
  manufacturerContactsApi: () => ManufacturerContactsApi;
  manufacturerMonthlyInvoicesApi: () => ManufacturerMonthlyInvoicesApi;
  manufacturerDataExchangesApi: () => ManufacturerDataExchangesApi;
  manufacturersApi: () => ManufacturersApi;
  manufacturerSettingsApi: () => ManufacturerSettingsApi;
  orderInvoiceAddressesApi: () => OrderInvoiceAddressesApi;
  orderPaymentTransactionsApi: () => OrderPaymentTransactionsApi;
  orderReceiptsApi: () => OrderReceiptsApi;
  orderReturnAnnouncementsApi: () => OrderReturnAnnouncementsApi;
  orderReturnsApi: () => OrderReturnsApi;
  ordersApi: () => OrdersApi;
  orderShippingAddressesApi: () => OrderShippingAddressesApi;
  orderShippingDetailsApi: () => OrderShippingDetailsApi;
  orderStateHistoriesApi: () => OrderStateHistoriesApi;
  paymentTransactionsApi: () => PaymentTransactionsApi;
  policiesApi: () => PoliciesApi;
  productAdditionalInformationApi: () => ProductAdditionalInformationApi;
  productAssetsApi: () => ProductAssetsApi;
  productBundlesApi: () => ProductBundlesApi;
  productCategoriesApi: () => ProductCategoriesApi;
  productGroupMappingsApi: () => ProductGroupMappingsApi;
  productGroupsApi: () => ProductGroupsApi;
  productPricesApi: () => ProductPricesApi;
  productsApi: () => ProductsApi;
  productStocksApi: () => ProductStocksApi;
  productUnitsApi: () => ProductUnitsApi;
  productVersionsApi: () => ProductVersionsApi;
  promotionsApi: () => PromotionsApi;
  propertyGroupsApi: () => PropertyGroupsApi;
  propertyOptionsApi: () => PropertyOptionsApi;
  propertyValidationRulesApi: () => PropertyValidationRulesApi;
  shippingProvidersApi: () => ShippingProvidersApi;
  tagsApi: () => TagsApi;
  taxTypesApi: () => TaxTypesApi;
  usersApi: () => UsersApi;
  userRolesApi: () => UserRolesApi;
  warehousesApi: () => WarehousesApi;
  // Shopify
  shopifyIntegrationLoggingApi: () => ShopifyIntegrationLoggingApi;
  shopifyAppApi: () => ShopifyAppApi;
  shopifyDeliveryInformationApi: () => ShopifyDeliveryInformationApi;
  shopifyIntegrationApi: () => ShopifyIntegrationApi;
  shopifyOrderExportApi: () => ShopifyOrderExportApi;
  shopifyProductImportApi: () => ShopifyProductImportApi;
  shopifyProductStocksApi: () => ShopifyProductStocksApi;
  // Shopware
  shopwareIntegrationLoggingApi: () => ShopwareIntegrationLoggingApi;
  shopwareDeliveryInformationApi: () => ShopwareDeliveryInformationApi;
  shopwareIntegrationApi: () => ShopwareIntegrationApi;
  shopwareOrderExportApi: () => ShopwareOrderExportApi;
  shopwareProductImportApi: () => ShopwareProductImportApi;
  shopwareProductStocksApi: () => ShopwareProductStocksApi;
  // Magento
  magentoIntegrationLoggingApi: () => MagentoIntegrationLoggingApi;
  magentoDeliveryInformationApi: () => MagentoDeliveryInformationApi;
  magentoIntegrationApi: () => MagentoIntegrationApi;
  magentoOrderExportApi: () => MagentoOrderExportApi;
  magentoProductImportApi: () => MagentoProductImportApi;
  magentoProductStocksApi: () => MagentoProductStocksApi;
};

const PetCloudApiContext = createContext({} as PetCloudApiContextValue);

export const usePetCloudApi = () => {
  return useContext(PetCloudApiContext);
};

export const PetCloudApiProvider: React.FC = ({ children }) => {
  const cookies = new Cookies();
  const { refreshToken, logout, setRedirectUri } = useAuthentication();

  const getAccessToken = () => {
    return cookies.get('access_token');
  };

  const getLanguage = () => {
    return cookies.get('i18next');
  };

  const getConfiguration = (api?: 'integration') => {
    const openapiConfig = new Configuration();
    openapiConfig.baseOptions = {
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        'Accept-Language': getLanguage(),
        'X-petcloud-client-lang': getLanguage(),
      },
    };
    if (api === 'integration') {
      openapiConfig.basePath =
        process.env.REACT_APP_CONNECT_API_BASE_PATH ?? undefined;
    } else {
      openapiConfig.basePath = process.env.REACT_APP_API_BASE_PATH ?? undefined;
    }
    return openapiConfig;
  };

  //REACT_APP_SHOPIFY_API_BASE_PATH

  const getAxiosInstance = () => {
    const instance = axios.create();
    instance.interceptors.request.use(
      async (config) => {
        const token = getAccessToken();
        if (!token) {
          // try to get a new access token with refresh token
          try {
            const newToken = await refreshToken();
            console.log(newToken);
            const adjustedConfig = {
              ...config,
              headers: {
                ...config.headers,
                Authorization: 'Bearer ' + newToken,
                'Accept-Language': getLanguage(),
                'X-petcloud-client-lang': getLanguage(),
              },
            };
            console.log('retrying call with copied headers...');
            return adjustedConfig;
          } catch {
            setRedirectUri(window.location.href);
            logout();
            return Promise.reject('failed');
          }
        } else {
          return {
            ...config,
            headers: {
              ...config.headers,
              Authorization: 'Bearer ' + token,
              'Accept-Language': getLanguage(),
              'X-petcloud-client-lang': getLanguage(),
            },
          };
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return instance;
  };

  const adyenAccountHolderApi = () => {
    return new AdyenAccountHolderApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const adyenBalanceAccountsApi = () => {
    return new AdyenBalanceAccountsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const adyenDocumentsApi = () => {
    return new AdyenDocumentsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const adyenGeneralApi = () => {
    return new AdyenGeneralApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const adyenLegalEntitiesApi = () => {
    return new AdyenLegalEntitiesApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const adyenNotificationsApi = () => {
    return new AdyenNotificationsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const adyenPaymentRefundsApi = () => {
    return new AdyenPaymentRefundsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const adyenPaymentSplitsApi = () => {
    return new AdyenPaymentSplitsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const adyenTransferInstrumentsApi = () => {
    return new AdyenTransferInstrumentsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const animalSpeciesApi = () => {
    return new AnimalSpeciesApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const assetsApi = () => {
    return new AssetsApi(getConfiguration(), undefined, getAxiosInstance());
  };
  const assetFoldersApi = () => {
    return new AssetFoldersApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const brandContactAddressesApi = () => {
    return new BrandContactAddressesApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const brandsApi = () => {
    return new BrandsApi(getConfiguration(), undefined, getAxiosInstance());
  };
  const bulkEditorHistoryApi = () => {
    return new BulkEditorHistoryApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const bulkEditorSessionApi = () => {
    return new BulkEditorSessionApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const brandStoresApi = () => {
    return new BrandStoresApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const countriesApi = () => {
    return new CountriesApi(getConfiguration(), undefined, getAxiosInstance());
  };
  const currenciesApi = () => {
    return new CurrenciesApi(getConfiguration(), undefined, getAxiosInstance());
  };
  const customersApi = () => {
    return new CustomersApi(getConfiguration(), undefined, getAxiosInstance());
  };
  const dashboardsApi = () => {
    return new DashboardsApi(getConfiguration(), undefined, getAxiosInstance());
  };
  const dataImportConfigsApi = () => {
    return new DataImportConfigsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const dataImportsApi = () => {
    return new DataImportsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const deliveryTimesApi = () => {
    return new DeliveryTimesApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const fiegePurchaseOrdersApi = () => {
    return new FiegePurchaseOrdersApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const informationGroupsApi = () => {
    return new InformationGroupsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const integrationsApi = () => {
    return new IntegrationsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const loyaltyPointProcessingConfigsApi = () => {
    return new LoyaltyPointProcessingConfigsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const loyaltyPointTransactionsApi = () => {
    return new LoyaltyPointTransactionsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const loyaltyRanksApi = () => {
    return new LoyaltyRanksApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const manufacturerConditionsApi = () => {
    return new ManufacturerConditionsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const manufacturerContactsApi = () => {
    return new ManufacturerContactsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const manufacturerDataExchangesApi = () => {
    return new ManufacturerDataExchangesApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const manufacturerMonthlyInvoicesApi = () => {
    return new ManufacturerMonthlyInvoicesApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const manufacturersApi = () => {
    return new ManufacturersApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const manufacturerSettingsApi = () => {
    return new ManufacturerSettingsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const orderReceiptsApi = () => {
    return new OrderReceiptsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const orderInvoiceAddressesApi = () => {
    return new OrderInvoiceAddressesApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const orderPaymentTransactionsApi = () => {
    return new OrderPaymentTransactionsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const orderReturnAnnouncementsApi = () => {
    return new OrderReturnAnnouncementsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const orderReturnsApi = () => {
    return new OrderReturnsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const ordersApi = () => {
    return new OrdersApi(getConfiguration(), undefined, getAxiosInstance());
  };
  const orderShippingAddressesApi = () => {
    return new OrderShippingAddressesApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const orderShippingDetailsApi = () => {
    return new OrderShippingDetailsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const orderStateHistoriesApi = () => {
    return new OrderStateHistoriesApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const paymentTransactionsApi = () => {
    return new PaymentTransactionsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const policiesApi = () => {
    return new PoliciesApi(getConfiguration(), undefined, getAxiosInstance());
  };
  const productAdditionalInformationApi = () => {
    return new ProductAdditionalInformationApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const productAssetsApi = () => {
    return new ProductAssetsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const productBundlesApi = () => {
    return new ProductBundlesApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const productCategoriesApi = () => {
    return new ProductCategoriesApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const productGroupMappingsApi = () => {
    return new ProductGroupMappingsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const productGroupsApi = () => {
    return new ProductGroupsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const productPricesApi = () => {
    return new ProductPricesApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const productsApi = () => {
    return new ProductsApi(getConfiguration(), undefined, getAxiosInstance());
  };
  const productStocksApi = () => {
    return new ProductStocksApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const productUnitsApi = () => {
    return new ProductUnitsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const productVersionsApi = () => {
    return new ProductVersionsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const promotionsApi = () => {
    return new PromotionsApi(getConfiguration(), undefined, getAxiosInstance());
  };
  const propertyGroupsApi = () => {
    return new PropertyGroupsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const propertyOptionsApi = () => {
    return new PropertyOptionsApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const propertyValidationRulesApi = () => {
    return new PropertyValidationRulesApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const shippingProvidersApi = () => {
    return new ShippingProvidersApi(
      getConfiguration(),
      undefined,
      getAxiosInstance()
    );
  };
  const tagsApi = () => {
    return new TagsApi(getConfiguration(), undefined, getAxiosInstance());
  };
  const taxTypesApi = () => {
    return new TaxTypesApi(getConfiguration(), undefined, getAxiosInstance());
  };
  const usersApi = () => {
    return new UsersApi(getConfiguration(), undefined, getAxiosInstance());
  };
  const userRolesApi = () => {
    return new UserRolesApi(getConfiguration(), undefined, getAxiosInstance());
  };
  const warehousesApi = () => {
    return new WarehousesApi(getConfiguration(), undefined, getAxiosInstance());
  };

  // Shopify
  const shopifyIntegrationLoggingApi = () => {
    return new ShopifyIntegrationLoggingApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const shopifyAppApi = () => {
    return new ShopifyAppApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const shopifyDeliveryInformationApi = () => {
    return new ShopifyDeliveryInformationApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const shopifyIntegrationApi = () => {
    return new ShopifyIntegrationApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const shopifyOrderExportApi = () => {
    return new ShopifyOrderExportApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const shopifyProductImportApi = () => {
    return new ShopifyProductImportApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const shopifyProductStocksApi = () => {
    return new ShopifyProductStocksApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };

  // Shopware
  const shopwareIntegrationLoggingApi = () => {
    return new ShopwareIntegrationLoggingApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const shopwareDeliveryInformationApi = () => {
    return new ShopwareDeliveryInformationApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const shopwareIntegrationApi = () => {
    return new ShopwareIntegrationApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const shopwareOrderExportApi = () => {
    return new ShopwareOrderExportApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const shopwareProductImportApi = () => {
    return new ShopwareProductImportApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const shopwareProductStocksApi = () => {
    return new ShopwareProductStocksApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };

  // Magento
  const magentoIntegrationLoggingApi = () => {
    return new MagentoIntegrationLoggingApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const magentoDeliveryInformationApi = () => {
    return new MagentoDeliveryInformationApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const magentoIntegrationApi = () => {
    return new MagentoIntegrationApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const magentoOrderExportApi = () => {
    return new MagentoOrderExportApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const magentoProductImportApi = () => {
    return new MagentoProductImportApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };
  const magentoProductStocksApi = () => {
    return new MagentoProductStocksApi(
      getConfiguration('integration'),
      undefined,
      getAxiosInstance()
    );
  };

  return (
    <PetCloudApiContext.Provider
      value={{
        adyenAccountHolderApi,
        adyenBalanceAccountsApi,
        adyenDocumentsApi,
        adyenGeneralApi,
        adyenLegalEntitiesApi,
        adyenNotificationsApi,
        adyenPaymentRefundsApi,
        adyenPaymentSplitsApi,
        adyenTransferInstrumentsApi,
        animalSpeciesApi,
        assetsApi,
        assetFoldersApi,
        brandContactAddressesApi,
        brandsApi,
        bulkEditorHistoryApi,
        bulkEditorSessionApi,
        brandStoresApi,
        countriesApi,
        currenciesApi,
        customersApi,
        dashboardsApi,
        dataImportConfigsApi,
        dataImportsApi,
        deliveryTimesApi,
        fiegePurchaseOrdersApi,
        informationGroupsApi,
        integrationsApi,
        loyaltyPointProcessingConfigsApi,
        loyaltyPointTransactionsApi,
        loyaltyRanksApi,
        manufacturerConditionsApi,
        manufacturerContactsApi,
        manufacturerDataExchangesApi,
        manufacturerMonthlyInvoicesApi,
        manufacturersApi,
        manufacturerSettingsApi,
        orderReceiptsApi,
        orderInvoiceAddressesApi,
        orderPaymentTransactionsApi,
        orderReturnAnnouncementsApi,
        orderReturnsApi,
        ordersApi,
        orderShippingAddressesApi,
        orderShippingDetailsApi,
        orderStateHistoriesApi,
        paymentTransactionsApi,
        policiesApi,
        productAdditionalInformationApi,
        productAssetsApi,
        productBundlesApi,
        productCategoriesApi,
        productGroupMappingsApi,
        productGroupsApi,
        productPricesApi,
        productsApi,
        productStocksApi,
        productUnitsApi,
        productVersionsApi,
        promotionsApi,
        propertyGroupsApi,
        propertyOptionsApi,
        propertyValidationRulesApi,
        shippingProvidersApi,
        tagsApi,
        taxTypesApi,
        usersApi,
        userRolesApi,
        warehousesApi,
        //Shopify
        shopifyIntegrationLoggingApi,
        shopifyAppApi,
        shopifyDeliveryInformationApi,
        shopifyIntegrationApi,
        shopifyOrderExportApi,
        shopifyProductImportApi,
        shopifyProductStocksApi,
        // Shopware
        shopwareIntegrationLoggingApi,
        shopwareDeliveryInformationApi,
        shopwareIntegrationApi,
        shopwareOrderExportApi,
        shopwareProductImportApi,
        shopwareProductStocksApi,
        // Magento
        magentoIntegrationLoggingApi,
        magentoDeliveryInformationApi,
        magentoIntegrationApi,
        magentoOrderExportApi,
        magentoProductImportApi,
        magentoProductStocksApi,
      }}
    >
      {children}
    </PetCloudApiContext.Provider>
  );
};
