import './unfulfilledorders.css';
import { UnfulfilledOrdersResponse } from '../../../api/petcloudapi/api';
import List from '../../list/List';
import { LoadingContainer } from '../../../elements/loading/Loading';
import { useCallback } from 'react';

interface UnfulfilledOrdersProps {
  unfulfilledOrders: UnfulfilledOrdersResponse[];
  height?: number;
}

const UnfulfilledOrders: React.FC<UnfulfilledOrdersProps> = ({
  unfulfilledOrders,
  height,
}) => {
  const renderManufacturer = useCallback(
    (id: string, order: UnfulfilledOrdersResponse) => {
      const m = order.manufacturer;
      if (m) {
        return (
          <div className={'orderList-manufacturerInfo'}>
            <img
              className={'orderList-manufacturerInfo-portrait'}
              src={m.logoAsset?.uri}
              alt={'logo'}
            />
            <div className={'orderList-manufacturerInfo-name'}>
              {m.companyName}
            </div>
          </div>
        );
      } else {
        return <div>error</div>;
      }
    },
    []
  );

  if (unfulfilledOrders) {
    return (
      <div className={'unfulfilledOrders'}>
        <List
          items={unfulfilledOrders}
          ignore={['orderId', 'manufacturerId']}
          monoSpaceStrings={['orderNumber']}
          dateStrings={['orderDateTime']}
          badgeKeys={['orderState', 'transactionState', 'shippingState']}
          renderObjects={[
            {
              key: 'manufacturer',
              renderMethod: renderManufacturer,
            },
          ]}
          linkedKey={'orderId'}
          linkPrefix={() => '/orders/'}
          adjustHeightToViewport
          adjustHeightToViewportOffset={140}
          isNewTabLink
          wrapInNewTabLink={['orderNumber']}
          height={height ?? 400}
          tableHeadContrast
          pageItemCountOptions={[25, 50, 100]}
          isShowingIndex
        />
      </div>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default UnfulfilledOrders;
