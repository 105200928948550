import './positiontransfer.css';
import List from '../../../../features/list/List';
import PositionEntry from '../../../../types/PositionEntry';
import { useState } from 'react';
import useListTools from '../../../../hooks/list/useListTools';
import Button from '../../../../elements/button/Button';
import { useTranslation } from 'react-i18next';
import { OrderShippingDetailResponse } from '../../../../api/petcloudapi/api';
import useListRenderObjects from '../../../../hooks/list/useListRenderObjects';

interface PositionTransferProps {
  currentShippingDetails: OrderShippingDetailResponse[] | null | undefined;
  orderPositions: PositionEntry[];
  onTransferPositions: (positions: PositionEntry[]) => void;
}

const PositionTransfer: React.FC<PositionTransferProps> = ({
  currentShippingDetails,
  orderPositions,
  onTransferPositions,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: 'view.order.shippingDetails.edit.positionTransfer',
  });
  const { toggleItem, toggleAllItems } = useListTools<PositionEntry>();
  const { renderInput } = useListRenderObjects();
  const [positionsData, setPositionsData] = useState(orderPositions);
  const [selectedPositions, setSelectedPositions] = useState<PositionEntry[]>(
    []
  );
  const currencySymbol = orderPositions[0].currency;

  // returns the maximum quantity of positions left based on previous shipping details
  const getMaxPositionQuantity = (position: PositionEntry) => {
    const shippedQuantity = currentShippingDetails?.reduce((prev, curr) => {
      const orderPositionQuantity = curr.positions?.find(
        (p) => p.orderPositionId === position.id
      )?.quantity;
      return prev + (orderPositionQuantity ?? 0);
    }, 0);
    console.log(shippedQuantity);
    const possibleQuantity = position.quantity - (shippedQuantity ?? 0);
    return possibleQuantity > 0 ? possibleQuantity : 0;
  };

  const getUnselectableIds = () => {
    return orderPositions
      .filter((p) => {
        return getMaxPositionQuantity(p) <= 0;
      })
      .map((p) => p.id);
  };

  return (
    <div className={'positionTransfer'}>
      <List
        items={orderPositions}
        unselectableIds={getUnselectableIds()}
        unselectableIdsDisableRows={true}
        ignore={[
          'id',
          'positionId',
          'productId',
          'currency',
          'bestBefore',
          'batchNumber',
        ]}
        itemImgKey={'coverUri'}
        priceKeysConfig={{
          priceKeys: ['price'],
          currencySymbol: currencySymbol,
        }}
        monoSpaceStrings={['ean']}
        selectedItems={selectedPositions ?? []}
        onSelect={(item) =>
          setSelectedPositions(
            toggleItem(selectedPositions, item) as PositionEntry[]
          )
        }
        onSelectAll={(items) =>
          setSelectedPositions(toggleAllItems(selectedPositions, items))
        }
        renderObjects={[
          {
            key: 'quantity',
            renderMethod: (value, item, index) =>
              renderInput(
                getMaxPositionQuantity(item),
                (newValue) => {
                  const update = [...positionsData];
                  update[index].quantity = parseInt(newValue);
                  setPositionsData(update);
                },
                {
                  type: 'number',
                  minValue: 0,
                  maxValue: getMaxPositionQuantity(item),
                }
              ),
          },
        ]}
      />
      <div className={'global-cardActions-postBorder'}>
        <Button
          cta={t('cta')}
          action={() => onTransferPositions(selectedPositions)}
          look={'save'}
          width={'minimal'}
          active={selectedPositions.length > 0}
        />
      </div>
    </div>
  );
};

export default PositionTransfer;
