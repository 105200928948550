import { useState } from 'react';

const usePromiseBatcher = <T,>() => {
  const [loadProgress, setLoadProgress] = useState<{
    title: string;
    total: number;
    loaded: number;
  } | null>(null);

  const resetLoadProgress = (total: number, title: string) => {
    setLoadProgress({
      title: title,
      total: total,
      loaded: 0,
    });
  };

  const incrementLoadProgress = () => {
    setLoadProgress((prevLoadProgress) => {
      return prevLoadProgress !== null
        ? {
            ...prevLoadProgress,
            loaded: prevLoadProgress.loaded + 1,
          }
        : prevLoadProgress;
    });
  };

  const batchPromises = (
    batchSize: number,
    items: (() => Promise<T>)[]
  ): Promise<T[]> => {
    const results: T[] = [];
    let index = 0;

    const executeNext = (): Promise<T[]> => {
      const batch = items
        .slice(index, index + batchSize)
        .map((p) => p().catch((e) => e));
      index += batchSize;
      if (batch.length) {
        return Promise.all(batch).then((res) => {
          results.push(...res);
          return executeNext();
        });
      } else {
        return Promise.resolve(results);
      }
    };

    return executeNext();
  };

  return {
    loadProgress,
    resetLoadProgress,
    incrementLoadProgress,
    batchPromises,
  };
};

export default usePromiseBatcher;
