import './separatorinput.css';
import { Dropdown } from '../../../../../../elements/selectors/Selectors';
import Input from '../../../../../../elements/input/Input';
import { ColumnHeaderMappingType } from '../../hooks/useDefaultMappings';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SeparatorInputProps {
  key: string;
  title: string;
  optionKey: string;
  hint?: string;
  maxWidth?: number;
  mapping: ColumnHeaderMappingType;
  setMapping: (m: ColumnHeaderMappingType) => void;
}

const SeparatorInput: React.FC<SeparatorInputProps> = ({
  key,
  title,
  optionKey,
  hint,
  maxWidth,
  mapping,
  setMapping,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix:
      'view.productBulkEditor.productImporter.columnHeaderConfig.mappingOptions.separatorInput',
  });
  const [isCustom, setIsCustom] = useState(false);

  const options = [
    {
      id: ',',
      name: t('comma'),
    },
    { id: ' ', name: t('space') },
    { id: 'custom', name: t('custom') },
    { id: '', name: t('none') },
  ];

  return (
    <div className={'mappingOption separatorInput'}>
      <div className={'global-inputGroup'}>
        <div className={'global-inputGroup-input separatorInput-dropdown'}>
          <Dropdown
            title={title}
            optionObjects={options}
            hint={hint ? { paragraphs: [hint] } : undefined}
            selected={
              isCustom
                ? t('custom')
                : options.find((x) => x.id === mapping.options[optionKey])?.name
            }
            update={(e) => {
              const value =
                e.currentTarget.selectedOptions[0].getAttribute('data-value');
              console.log(value);
              if (value !== 'custom') {
                setIsCustom(false);
                setMapping({
                  ...mapping,
                  options: {
                    ...mapping.options,
                    [optionKey]: value,
                  },
                });
              } else {
                setIsCustom(true);
              }
            }}
            widthAuto
            disableAutoOptionSorting
            disabled={!mapping.mappedKey}
          />
        </div>
        {isCustom ? (
          <div className={'global-inputGroup-input separatorInput-input'}>
            <Input
              key={key}
              title={!isCustom ? title : undefined}
              content={mapping.options[optionKey]}
              update={(v) => {
                setMapping({
                  ...mapping,
                  options: {
                    ...mapping.options,
                    [optionKey]: v,
                  },
                });
              }}
              maxWidth={maxWidth}
              hint={hint}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SeparatorInput;
