import './mappingoptions.css';
import { ReactNode, useMemo } from 'react';
import {
  Check,
  Dropdown,
  DropdownOption,
} from '../../../../../elements/selectors/Selectors';
import useLanguages from '../../../../../hooks/useLanguages';
import { useTranslation } from 'react-i18next';
import { ColumnHeaderMappingType } from '../hooks/useDefaultMappings';
import SeparatorInput from './separatorinput/SeparatorInput';
import { SheetData } from '../../ProductImporter';
import DropdownMenu from '../../../../../elements/dropdownmenu/DropdownMenu';

interface MappingOptionsProps {
  mapping: ColumnHeaderMappingType;
  setMapping: (m: ColumnHeaderMappingType) => void;
  sheetData: SheetData;
}

const MappingOptions: React.FC<MappingOptionsProps> = ({
  mapping,
  setMapping,
  sheetData,
}) => {
  const { availableLanguages } = useLanguages();
  const { t } = useTranslation('translations', {
    keyPrefix:
      'view.productBulkEditor.productImporter.columnHeaderConfig.mappingOptions',
  });

  // const createInput = (
  //   key: string,
  //   title: string,
  //   optionKey: string,
  //   hint?: string,
  //   maxWidth?: number
  // ) => {
  //   return (
  //     <div className={'mappingOption'}>
  //       <div className={'global-inputGroup'}>
  //         <div className={'global-inputGroup-input'}>
  //           <Input
  //             key={key}
  //             title={title}
  //             content={mapping.options[optionKey]}
  //             update={(v) => {
  //               setMapping({
  //                 ...mapping,
  //                 options: {
  //                   ...mapping.options,
  //                   [optionKey]: v,
  //                 },
  //               });
  //             }}
  //             maxWidth={maxWidth}
  //             hint={hint}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const createDropdown = (
    key: string,
    title: string,
    optionKey: string,
    options: string[],
    hint?: string
  ) => {
    return (
      <div className={'mappingOption'}>
        <div className={'global-inputGroup'}>
          <div className={'global-inputGroup-input'}>
            <Dropdown
              key={key}
              title={title}
              selected={
                mapping.mappedKey ? mapping.options[optionKey] : undefined
              }
              options={options}
              update={(e) => {
                setMapping({
                  ...mapping,
                  options: { ...mapping.options, [optionKey]: e.target.value },
                });
              }}
              widthAuto={true}
              hint={hint ? { paragraphs: [hint] } : undefined}
              disabled={!mapping.mappedKey}
            />
          </div>
        </div>
      </div>
    );
  };

  const getSourceBoolKeys = () => {
    if (mapping.mappedKey) {
      console.log(mapping.mappedKey);
      const mappedKeyIndex = sheetData.headers.indexOf(mapping.mappedKey);
      console.log(mappedKeyIndex);
      const result = sheetData.data.reduce((prev, next) => {
        const v = next[mappedKeyIndex];
        console.log(v);
        if (v && !prev.includes(v)) {
          prev.push(next[mappedKeyIndex]);
        }
        return prev;
      }, []);
      console.log(result);
      return result;
    } else {
      return [];
    }
  };

  const units: DropdownOption[] = useMemo(
    () => [
      {
        id: 'kg',
        name: t('unitConvertMultiplier.kg'),
      },
      {
        id: 'g',
        name: t('unitConvertMultiplier.g'),
      },
      {
        id: 'mg',
        name: t('unitConvertMultiplier.mg'),
      },
      {
        id: 'm',
        name: t('unitConvertMultiplier.m'),
      },
      {
        id: 'cm',
        name: t('unitConvertMultiplier.cm'),
      },
      {
        id: 'mm',
        name: t('unitConvertMultiplier.mm'),
      },
    ],
    []
  );

  const renderMappingOptions = () => {
    const opts: ReactNode[] = [];
    if (mapping.options?.lang !== undefined) {
      opts.push(
        createDropdown(mapping.key, t('language'), 'lang', availableLanguages)
      );
    }
    if (mapping.options?.languageUniform !== undefined) {
      opts.push(
        <div className={'mappingOption'}>
          <Check
            key={mapping.key}
            text={t('languageUniform')}
            checked={mapping.options.languageUniform}
            update={() => {
              setMapping({
                ...mapping,
                options: {
                  ...mapping.options,
                  languageUniform: !mapping.options.languageUniform,
                },
              });
            }}
            disabled={!mapping.mappedKey}
          />
        </div>
      );
    }
    if (mapping.options?.languageMap !== undefined) {
      const languageMap = mapping.options.languageMap;
      availableLanguages.forEach((lang) => {
        opts.push(
          <div className={'mappingOption'}>
            <div className={'global-inputGroup'}>
              <div className={'global-inputGroup-input'}>
                <DropdownMenu
                  key={mapping.key + lang}
                  title={lang}
                  selected={languageMap[lang] ?? undefined}
                  options={sheetData.headers}
                  onSelect={(v) => {
                    setMapping({
                      ...mapping,
                      mappedKey: lang === 'de-DE' ? v : mapping.mappedKey,
                      options: {
                        ...mapping.options,
                        languageMap: {
                          ...mapping.options.languageMap,
                          [lang]: v,
                        },
                      },
                    });
                  }}
                  widthAuto={true}
                  disabled={
                    !mapping.mappedKey ||
                    (lang !== 'de-DE' && mapping.options.languageUniform)
                  }
                />
              </div>
            </div>
          </div>
        );
      });
    }
    if (mapping.options?.separator !== undefined) {
      opts.push(
        <SeparatorInput
          key={mapping.key}
          title={t('separator')}
          optionKey={'separator'}
          hint={t('separator_hint')}
          maxWidth={100}
          mapping={mapping}
          setMapping={setMapping}
        />
      );
    }
    if (mapping.options?.nameValuesSeparator !== undefined) {
      opts.push(
        <SeparatorInput
          key={mapping.key}
          title={t('nameValuesSeparator')}
          optionKey={'nameValuesSeparator'}
          hint={t('nameValuesSeparator_hint')}
          maxWidth={100}
          mapping={mapping}
          setMapping={setMapping}
        />
      );
    }
    if (mapping.options?.valueSeparator !== undefined) {
      opts.push(
        <SeparatorInput
          key={mapping.key}
          title={t('valueSeparator')}
          optionKey={'valueSeparator'}
          hint={t('valueSeparator_hint')}
          maxWidth={100}
          mapping={mapping}
          setMapping={setMapping}
        />
      );
    }
    if (mapping.options?.trueKey !== undefined) {
      opts.push(
        createDropdown(
          mapping.key,
          t('trueKey'),
          'trueKey',
          getSourceBoolKeys(),
          t('trueKey_hint')
        )
      );
    }
    if (mapping.options?.falseKey !== undefined) {
      opts.push(
        createDropdown(
          mapping.key,
          t('falseKey'),
          'falseKey',
          getSourceBoolKeys(),
          t('falseKey_hint')
        )
      );
    }
    if (mapping.options?.originalUnit !== undefined) {
      opts.push(
        <div className={'mappingOption'}>
          <div className={'global-inputGroup'}>
            <div className={'global-inputGroup-input'}>
              <Dropdown
                key={mapping.key}
                title={t('unitConvertMultiplier.title')}
                selected={
                  mapping.mappedKey
                    ? units.find((x) => x.id === mapping.options.originalUnit)
                        ?.name
                    : undefined
                }
                optionObjects={units}
                update={(e) => {
                  const v =
                    e.currentTarget.selectedOptions[0].getAttribute(
                      'data-value'
                    );
                  setMapping({
                    ...mapping,
                    options: {
                      ...mapping.options,
                      originalUnit: v,
                    },
                  });
                }}
                widthAuto={true}
                hint={t('unitConvertMultiplier.hint')}
                disabled={!mapping.mappedKey}
                disableAutoOptionSorting
              />
            </div>
          </div>
        </div>
      );
    }
    return opts;
  };

  return <div className={'mappingOptions'}>{renderMappingOptions()}</div>;
};

export default MappingOptions;
