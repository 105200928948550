import './integrationskeleton.css';
import { Has } from '../../../../contexts/auth/Authorization';
import {
  Navigator,
  Stage,
  StageColumn,
  StageColumns,
} from '../../../../layout/stage/Stage';
import Card, { CardSection } from '../../../../elements/card/Card';
import ToggleSwitch from '../../../../elements/toggleswitch/ToggleSwitch';
import Button from '../../../../elements/button/Button';
import Popup from '../../../../elements/popup/Popup';
import installApp from '../../../../../assets/img/integration_app_install.svg';
import { LoadingContainer } from '../../../../elements/loading/Loading';
import { useTranslation } from 'react-i18next';
import useIntegrationSubModules from '../hooks/useIntegrationSubModules';
import { IntegrationSubModuleResponse } from '../../../../api/petcloudapi/api';
import { ReactNode, useState } from 'react';
import useManufacturerOptions from '../../../../hooks/data/useManufacturerOptions';
import { ReactComponent as IconLink } from '../../../../../assets/icon/link.svg';
import { EmptyState } from '../../../../elements/emptystate/EmptyState';

interface IntegrationSkeletonProps {
  translationNamespace: string;
  integration: any;
  integrationId: string;
  manufacturerId?: string;
  renderSubModules: (
    modules: IntegrationSubModuleResponse[],
    hasProductImportConfig: boolean,
    hasOrderExportConfig: boolean
  ) => ReactNode;
  updateIntegration: (req: any) => void;
  resetConfig: () => void;
  isResetting: boolean;
  onSetup?: () => void;
  isIntegrationLoading?: boolean;
}

const IntegrationSkeleton: React.FC<IntegrationSkeletonProps> = ({
  translationNamespace,
  integration,
  integrationId,
  manufacturerId,
  renderSubModules,
  updateIntegration,
  resetConfig,
  isResetting,
  onSetup,
  isIntegrationLoading,
}) => {
  const { t } = useTranslation('translations', {
    keyPrefix: `view.integration.${translationNamespace}`,
  });

  const { sortedSubModules, hasProductImportConfig, hasOrderExportConfig } =
    useIntegrationSubModules(integrationId, manufacturerId);
  const { manufacturerDict } = useManufacturerOptions();
  const [dangerPopup, setDangerPopup] = useState(false);

  if (
    integration &&
    sortedSubModules &&
    hasProductImportConfig !== null &&
    hasOrderExportConfig !== null
  ) {
    return (
      <Has authorizations={['integrations_api:list']} showNoAuthorization>
        <Stage>
          <Navigator
            title={`${t('title')}${
              manufacturerDict &&
              manufacturerId &&
              manufacturerDict !== 'NO_PERMISSION'
                ? ': ' + manufacturerDict[manufacturerId].companyName
                : ''
            }`}
            allowBackButton
          ></Navigator>
          {integration.installed ? (
            <StageColumns>
              <StageColumn size={'two'}>
                {integration.enabled ? (
                  renderSubModules(
                    sortedSubModules,
                    hasProductImportConfig,
                    hasOrderExportConfig
                  )
                ) : (
                  <Card bigScreenWidth={'100%'}>
                    <CardSection>
                      <EmptyState message={t('disabled')} />
                    </CardSection>
                  </Card>
                )}
              </StageColumn>
              <StageColumn size={'one'}>
                <Card bigScreenWidth={'100%'}>
                  <CardSection title={t('settings.title')}>
                    <ToggleSwitch
                      title={t('settings.toggle')}
                      toggled={integration.enabled}
                      toggle={() => {
                        const update = {
                          ...integration,
                          enabled: !integration.enabled,
                        };
                        updateIntegration(update);
                      }}
                      isLoading={isIntegrationLoading}
                    />
                  </CardSection>
                  <CardSection>
                    <Button
                      cta={t('settings.reset')}
                      width={'full'}
                      look={'secondary-danger'}
                      action={() => setDangerPopup(true)}
                    />
                    <Popup
                      toggled={dangerPopup}
                      width={'20%'}
                      close={() => setDangerPopup(false)}
                    >
                      <div className={'popup-title'}>
                        {t('settings.dangerPopup.title')}
                      </div>
                      <div className={'global-textElement'}>
                        {t('settings.dangerPopup.text')}
                      </div>
                      <Button
                        cta={t('settings.dangerPopup.cta')}
                        width={'full'}
                        look={'danger'}
                        action={resetConfig}
                        isLoading={isResetting}
                      />
                    </Popup>
                  </CardSection>
                  {onSetup ? (
                    <CardSection>
                      <Button
                        cta={t('settings.setup')}
                        width={'full'}
                        look={'secondary'}
                        action={onSetup}
                      />
                    </CardSection>
                  ) : null}
                </Card>
              </StageColumn>
            </StageColumns>
          ) : (
            <Card bigScreenWidth={'50%'} title={t('emptyState.title')}>
              <CardSection>
                <img
                  src={installApp}
                  style={{
                    display: 'block',
                    width: 300,
                    margin: '0 auto',
                    marginBottom: 20,
                  }}
                  alt={'App Install'}
                />
                <div className={'global-textElement'}>
                  {t('emptyState.text')}
                </div>
                <Button
                  cta={t('emptyState.cta')}
                  width={'minimal'}
                  look={'primary'}
                  type={'icon-text'}
                  action={
                    integration?.installationLink
                      ? () => window.open(integration.installationLink)?.focus()
                      : undefined
                  }
                >
                  <IconLink className={'button-icon button-icon-primary'} />
                </Button>
              </CardSection>
            </Card>
          )}
        </Stage>
      </Has>
    );
  } else {
    return <LoadingContainer />;
  }
};

export default IntegrationSkeleton;
