import { ColumnHeaderMappingType } from './useDefaultMappings';
import useImportFunctions from './useImportFunctions';
import { constituentslist } from '../../../../../sections/product/analyticconstituents/constituentslist';

const usePreviewFunctions = () => {
  const { splitAnalyticConstituents, splitBySeparator } = useImportFunctions();

  const previewAnalyticConstituents = (
    mapping: ColumnHeaderMappingType,
    exampleValues: string[]
  ) => {
    const { options } = mapping;
    const constituents = splitAnalyticConstituents(
      exampleValues[0],
      options.nameValuesSeparator,
      options.valueSeparator,
      options.separator
    );
    const availableConstituents = constituentslist;
    return constituents?.slice(0, 3).map((c) => {
      const match = availableConstituents.find((x) => {
        return !!Object.entries(x.name).find(
          ([lang, translatedName]) => translatedName === c.name
        );
      });
      return (
        <div
          className={`columnHeaderConfig-info-config-preview-value ${
            !match ? 'columnHeaderConfig-info-config-preview-value_invalid' : ''
          }`}
        >{`${c.name}: ${c.value}%`}</div>
      );
    });
  };

  const previewBulletpoints = (
    mapping: ColumnHeaderMappingType,
    exampleValues: string[]
  ) => {
    const { options } = mapping;
    const bulletPoints = splitBySeparator(exampleValues[0], options.separator);
    return bulletPoints?.map((point: string, index: number) => (
      <div key={index} className="columnHeaderConfig-info-config-preview-value">
        {point}
      </div>
    ));
  };

  const previewAdditionalInformationDownloads = (
    mapping: ColumnHeaderMappingType,
    exampleValues: string[]
  ) => {
    const links = splitBySeparator(exampleValues[0], mapping.options.separator);
    return links?.map((link: string, index: number) => (
      <div key={index} className="columnHeaderConfig-info-config-preview-value">
        <a href={link} target={'_blank'} rel="noreferrer">
          {link}
        </a>
      </div>
    ));
  };

  return {
    previewAnalyticConstituents,
    previewBulletpoints,
    previewAdditionalInformationDownloads,
  };
};

export default usePreviewFunctions;
